import React, { useEffect, useState, useRef } from 'react';
import { Box, Container, Typography, Button, Modal, MenuItem, Select } from '@mui/material';
import Heading from '../../Components/heading.tsx';
import TripsList from '../../Components/trip_list.tsx';
import getCurrentDate from '../../../../Utils/CurrentDate/current-date.tsx';
import { getTrips } from './action.ts';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const styles = {
  statusContainer: {
    backgroundColor: '#f0f0f0',
    paddingBottom: '16px',
    borderRadius: '8px',
    width: '100%',
    marginTop: '30px',
  },
  tripContainer: {
    backgroundColor: '#f0f0f0',
    paddingRight: '0px',
    paddingLeft: '0px',
  },
  dateDropdown: {
    margin: '0 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor:'white'
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
  },
  modalTitle: {
    fontWeight: 'bold',
  },
  clearBtnStyle: {
    backgroundColor: 'red',
    color: 'white',
  },
  buttonsStyle: {
    backgroundColor: 'green',
    color: 'white',
    marginRight: '5px',
  },
  noTrips: {
    fontWeight: 'bold',
    marginTop: '20px',
    marginLeft: '10px',
    marginRight: '10px',
    alignItems: 'center',
    height: '700px',
    textAlign: 'center',
  },
};

const handleBack = () => {
  window.history.back();
};

const ShowMoreTrips: React.FC = () => {
  const today = new Date();
  const defaultDay = today.getDate();
  const defaultMonth = today.toLocaleString('default', { month: 'short' });
  const defaultYear = today.getFullYear();
  
  const [trips, setTrips] = useState([]);
  const [noTrips, setNoTrips] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  
  const [startDate, setStartDate] = useState({
    day: defaultDay,
    month: defaultMonth,
    year: defaultYear,
  });
  const [endDate, setEndDate] = useState({
    day: defaultDay,
    month: defaultMonth,
    year: defaultYear,
  });
  const [dateType, setDateType] = useState('start');
  const [page, setPage] = useState(0);
  const [hasMoreTrips, setHasMoreTrips] = useState(true);
  
  const [tempDate, setTempDate] = useState({
    day: defaultDay,
    month: defaultMonth,
    year: defaultYear,
  });
  
  const loading = useRef(false);  // Track if an API call is in progress

  const params = new URLSearchParams(window.location.search);
  const numberPlate = params.get('number_plate');
  const vehicleId = params.get('vehicle_id');
  const currentDate = getCurrentDate();

  const fetchTrips = () => {
    const startDateFormatted = `${startDate.year}-${String(
      new Date(Date.parse(startDate.month + ' 1, ' + startDate.year)).getMonth() + 1).padStart(2, '0')}-${String(startDate.day).padStart(2, '0')}`;
    const endDateFormatted = `${endDate.year}-${String(
      new Date(Date.parse(endDate.month + ' 1, ' + endDate.year)).getMonth() + 1).padStart(2, '0')}-${String(endDate.day).padStart(2, '0')}`;

    if (loading.current || !hasMoreTrips) return;  // Prevent calling API if already loading or no more trips

    loading.current = true;  // Set the loading flag to true
    getTrips(
      10,
      page,
      startDateFormatted,
      endDateFormatted,
      (response) => {
        const totalTripsCount = response.message.total_trips_count;
        const newTrips = response.message.trips_list;

        if (newTrips.length === 0 || newTrips === 'no trips fetched') {
          setHasMoreTrips(false); 
        } else {
          setTrips((prevTrips) => [...prevTrips, ...newTrips]);
        }

        loading.current = false;
        setNoTrips(totalTripsCount === 0);
      },
      (errorMessage) => {
        loading.current = false;
        console.log(errorMessage);
        if (errorMessage.message.total_trips_count === 0) {
          setNoTrips(true);
        }
        if (errorMessage?.message?.errors?.from_date) {
          toast.error("Invalid From Date");
        } else if (errorMessage?.message?.errors?.to_date) {
          toast.error("Invalid To Date");
        }
      },
      vehicleId,
      '',
      ''
    );
  };

  useEffect(() => {
    fetchTrips();
  }, [page]);

  useEffect(() => {
    setTrips([]);
    setPage(0);
    setHasMoreTrips(true);
    fetchTrips();
  }, [startDate, endDate]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      if (!loading.current && hasMoreTrips) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasMoreTrips]);

  const handleDateChange = (event, field) => {
    setTempDate((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleOpenModal = (type) => {
    setDateType(type);
    if (type === 'start') {
      setTempDate(startDate); // Show the start date
    } else {
      setTempDate(endDate); // Show the previously selected end date
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmDateChange = () => {
    if (dateType === 'start') {
      setStartDate(tempDate);
    } else {
      setEndDate(tempDate);
    }
    handleCloseModal();
  };

  return (
    <Container style={styles.tripContainer}>
      <Box>
        <Box style={styles.statusContainer}>
          <Heading title="Trips" onBackClick={handleBack} optionalTitle={numberPlate} />
        </Box>

        <div>
          <Box display="flex" justifyContent="center" marginBottom="16px">
            <Button
              variant="outlined"
              style={styles.dateDropdown}
              onClick={() => handleOpenModal('start')}
            >
              <span>{`${startDate.day} ${startDate.month} ${startDate.year}`}</span>
              <ArrowDropDownIcon />
            </Button>
            <Button
              variant="outlined"
              style={styles.dateDropdown}
              onClick={() => handleOpenModal('end')}
            >
              <span>{`${endDate.day} ${endDate.month} ${endDate.year}`}</span>
              <ArrowDropDownIcon />
            </Button>
          </Box>

          {noTrips ? (
            <Typography style={styles.noTrips}>
              No trips available
            </Typography>
          ) : (
            <Box>
              <TripsList trips={trips} />
            </Box>
          )}
        </div>

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box style={styles.modalContent}>
            <Typography style={styles.modalTitle}>
              Select {dateType === 'start' ? 'Start' : 'End'} Date
            </Typography>
            <Box display="flex" justifyContent="center" marginBottom="16px">
              <Select value={tempDate.day} onChange={(e) => handleDateChange(e, 'day')}>
                {[...Array(31).keys()].map((day) => (
                  <MenuItem key={day + 1} value={day + 1}>
                    {day + 1}
                  </MenuItem>
                ))}
              </Select>
              <Select value={tempDate.month} onChange={(e) => handleDateChange(e, 'month')}>
                {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map(
                  (month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  )
                )}
              </Select>
              <Select value={tempDate.year} onChange={(e) => handleDateChange(e, 'year')}>
                {[defaultYear - 1, defaultYear, defaultYear + 1].map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box display="flex" justifyContent="center" marginTop="16px">
              <Button onClick={handleCloseModal} style={styles.clearBtnStyle}>
                Cancel
              </Button>
              <Button onClick={handleConfirmDateChange} style={styles.buttonsStyle}>
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Container>
  );
};

export default ShowMoreTrips;

import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SpeedIcon from '@mui/icons-material/Speed';  
import routeIcon from '../../../assets/ic_route.png';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';


const styles = {
    title: {
        fontWeight: 'bold',
        fontFamily: 'Montserrat, sans-serif',

    },
    regularText: {
      fontSize: 20, 
      color: '#68E0CF',
      fontFamily: 'Montserrat, sans-serif',
    }
}
const TripsList = ({ trips }) => {
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = dateTime.toLocaleDateString(undefined, options);
    const time = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return { date, time };
  };

  return (
    <div style={{ padding: '16px' }}>
      {trips.map((trip, index) => {
        const start = formatDateTime(trip.start_time);
        const end = formatDateTime(trip.end_time);
        const distance = trip.end_time ? trip.odometer_end - trip.odometer_start
          : 0;
        return (
          <Paper
            key={trip.id}
            elevation={3}
            style={{
              backgroundColor: '#fff',
              marginBottom: '16px',
              padding: '16px',
              borderRadius: '8px',
            }}
          >
             <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="8px">
            <Typography style={styles.title}>Trip {index + 1}</Typography>
           
              <Typography variant="body1">{start.date}</Typography>
              <Typography variant="body1"><img  src={routeIcon} alt="route_img" style={{scale:'0.5'}} />{distance}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography style={styles.title}>Start</Typography>
              <Typography variant="body1" ><AccessTimeIcon style={styles.regularText} />
              {start.time}</Typography>
              <Typography variant="body1"> <SpeedIcon style={styles.regularText} />{trip.odometer_start || ''}</Typography>
              <Typography variant="body1"> <ShoppingCartOutlinedIcon style={styles.regularText} />{trip.orders_picked || ''}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography style={styles.title} sx={{marginRight:'10px'}}>End</Typography>
              <Typography variant="body1" ><AccessTimeIcon style={styles.regularText} />{end.time}</Typography>
              <Typography variant="body1"><SpeedIcon style={{ fontSize: 20, color: '#68E0CF' }} />{trip.odometer_end || ''}</Typography>
              <Typography variant="body1"><ShoppingCartOutlinedIcon style={styles.regularText} /> {trip.orders_delivered || ''}</Typography>
            </Box>
          </Paper>
        );
      })}
    </div>
  );
};

export default TripsList;

 export const validateGST = (gst: string, pan: string): string => {
     const length = gst.length;
     if (length === 0) return ''; 
     if (length >= 1 && !/^\d{1}/.test(gst.substring(0, 1))) {
          return 'The first character must be a digit (State Code)';
     }
    if (length >= 2 && !/^\d{2}/.test(gst.substring(0, 2))) {
          return 'The second character must be a digit (State Code)';
    }
    if (length >= 3) {
          if (!/^\d{2}[A-Z]{1}/.test(gst.substring(0, 3))) {
            return 'The third character must be an alphabet';
          }
          if (pan && gst[2] !== pan[0]) {
            return 'The third character of GST must match the first character of the PAN';
          }
    }
    if (length >= 4) {
          if (!/^\d{2}[A-Z]{2}/.test(gst.substring(0, 4))) {
            return 'The fourth character must be an alphabet';
          }
          if (pan && gst[3] !== pan[1]) {
            return 'The fourth character of GST must match the second character of the PAN';
          }
    }
    if (length >= 5) {
          if (!/^\d{2}[A-Z]{3}/.test(gst.substring(0, 5))) {
            return 'The fifth character must be an alphabet';
          }
          if (pan && gst[4] !== pan[2]) {
            return 'The fifth character of GST must match the third character of the PAN';
          }
    }
    if (length >= 6) {
          if (!/^\d{2}[A-Z]{4}/.test(gst.substring(0, 6))) {
            return 'The sixth character must be an alphabet';
          }
          if (pan && gst[5] !== pan[3]) {
            return 'The sixth character of GST must match the fourth character of the PAN';
          }
    }
    if (length >= 7) {
          if (!/^\d{2}[A-Z]{5}/.test(gst.substring(0, 7))) {
            return 'The seventh character must be an alphabet';
          }
          if (pan && gst[6] !== pan[4]) {
            return 'The seventh character of GST must match the fifth character of the PAN';
          }
    }
    if (length >= 8) {
          if (!/^\d{2}[A-Z]{5}\d{1}/.test(gst.substring(0, 8))) {
            return 'The eighth character must be a digit';
          }
          if (pan && gst[7] !== pan[5]) {
            return 'The eighth character of GST must match the sixth character of the PAN';
          }
    }
    if (length >= 9) {
          if (!/^\d{2}[A-Z]{5}\d{2}/.test(gst.substring(0, 9))) {
            return 'The ninth character must be a digit';
          }
          if (pan && gst[8] !== pan[6]) {
            return 'The ninth character of GST must match the seventh character of the PAN';
          }
    }
    if (length >= 10) {
          if (!/^\d{2}[A-Z]{5}\d{3}/.test(gst.substring(0, 10))) {
            return 'The tenth character must be a digit';
          }
          if (pan && gst[9] !== pan[7]) {
            return 'The tenth character of GST must match the eighth character of the PAN';
          }
    }
    if (length >= 11) {
          if (!/^\d{2}[A-Z]{5}\d{4}/.test(gst.substring(0, 11))) {
            return 'The eleventh character must be a digit';
          }
          if (pan && gst[10] !== pan[8]) {
            return 'The eleventh character of GST must match the ninth character of the PAN';
          }
    }
    if (length >= 12) {
          if (!/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}/.test(gst.substring(0, 12))) {
            return 'The twelfth character must be an alphabet';
          }
          if (pan && gst[11] !== pan[9]) {
            return 'The twelfth character of GST must match the tenth character of the PAN';
          }
    }
    if (length >= 13 && !/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}/.test(gst.substring(0, 13))) {
            return 'The thirteenth character must be a digit.';
          }
    if (length >= 14 && gst[13] !== 'Z') {
            return 'The fourteenth character must be "Z"';
    }
    if (length >= 15 && !/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}Z[A-Z0-9]{1}/.test(gst)) {
            return 'The fifteenth character must be an alphabet or digit';
    }
    if (length > 15) {
            return 'GST number must be exactly 15 characters';
    }
    return '';
};
      
export const validateAccountNumber = (accountNumber: string): string => {
  const length = accountNumber.length;
  if (length === 0) return '';
  if (length > 18) return 'Account number must not be greater than 18 characters';
  for (let i = 0; i < length; i++) {
    if (!/^\d$/.test(accountNumber[i])) {
      return `It must be a digit`;
    }
  }
  return ''; 
};

export const validateTan = (tan: string): string => {
  const length = tan.length;
  if (length === 0) return ''; 
  if (length >= 1 && !/^[A-Z]{1}/.test(tan)) {
    return 'The first character must be an alphabet';
  }
  if (length >= 2 && !/^[A-Z]{2}/.test(tan)) {
    return 'The second character must be an alphabet';
  }
  if (length >= 3 && !/^[A-Z]{3}/.test(tan)) {
    return 'The third character must be an alphabet';
  }
  if (length >= 4 && !/^[A-Z]{4}/.test(tan)) {
    return 'The fourth character must be an alphabet';
  }
  if (length >= 5 && !/^[A-Z]{4}\d{1}/.test(tan)) {
    return 'The fifth character must be a digit';
  }
  if (length >= 6 && !/^[A-Z]{4}\d{2}/.test(tan)) {
    return 'The sixth character must be a digit';
  }
  if (length >= 7 && !/^[A-Z]{4}\d{3}/.test(tan)) {
    return 'The seventh character must be a digit';
  }
  if (length >= 8 && !/^[A-Z]{4}\d{4}/.test(tan)) {
    return 'The eighth character must be a digit';
  }
  if (length >= 9 && !/^[A-Z]{4}\d{5}/.test(tan)) {
    return 'The ninth character must be a digit';
  }
  if (length >= 10 && !/^[A-Z]{4}\d{5}[A-Za-z]{1}/.test(tan)) {
    return 'The tenth character must be an alphabet';
  }
  if (length > 10) {
    return 'TAN must be exactly 10 characters';
  }
  return ''; 
};

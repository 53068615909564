import axios, { AxiosResponse, AxiosError } from "axios";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const params = new URLSearchParams(window.location.search);
const token = params.get('token');
export const getDashboard = (
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${global.api_url}/api/dashboard`, {
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
        else if(response.status === 201) {
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else if( error.response?.status === 404)
          {
            _errorCallBack(error.response.data);
          }
        else{
          toast.error("Server Error");
        }
      });
  };
  export const getTrips = (
    per_page_records: number,
    page_number: number,
    from_date: string,
    to_date: string,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
    vehicle_id?: number,
    client_name?:any,
    fuel_type?:string
  ) => {
    console.log(token,"trips page");
    const headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${global.api_url}/api/get_trips`, {
        params: {
          per_page_records,
          page_number,
          from_date,
          to_date,
          vehicle_id,
          client_name,
          fuel_type
        },
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else{
          toast.error("Server Error");
        }
      });
  };
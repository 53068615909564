export const validateDriverName = (name) => {
  const regex = /^[A-Za-z\s]+$/;  
  return regex.test(name);
};

export const validateCompanyName = (name) => {
  const regex = /^[a-zA-Z0-9 .]+$/;
  return regex.test(name);
};



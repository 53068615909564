import axios, { AxiosResponse, AxiosError } from "axios";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const storedUserDetails = localStorage.getItem("userdetails");
const token = storedUserDetails ? JSON.parse(storedUserDetails).token.replace(/^"(.*)"$/, '$1') : null;

export const getVehicle = (
  per_page_records:any,
  page_number:any,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
  number_plate?:any,
  client_name?:any,
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${global.api_url}/api/vehicles`, {
        params :{ per_page_records, page_number,number_plate, client_name}, 
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
        else if (response.status === 201){
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if( error.response?.status === 404)
        {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else{
          toast.error("Server Error");
        }
      });
  };

  export const getDocuments = (
    vehicles_id:any,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${global.api_url}/api/documents`, {
        params: {vehicles_id},
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
        else if(response.status === 201) {
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else if( error.response?.status === 404)
          {
            _errorCallBack(error.response.data);
          }
        else{
          toast.error("Server Error");
        }
      });
  };

  export const addDocuments = (
    drivers_license: File | null,
    aadhar: File | null,
    reg_cert: File | null,
    insurance: File | null,
    fitness_cert: File | null,
    pollution_cert: File | null,
    vehicles_id: number,
    fitness_expiry: string | null,
    insurance_expiry: string | null,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void
  ) => {
    const formData = new FormData();
    if (drivers_license) formData.append('drivers_license', drivers_license);
    if (aadhar) formData.append('aadhar', aadhar);
    if (reg_cert) formData.append('reg_cert', reg_cert);
    if (insurance) formData.append('insurance', insurance);
    if (fitness_cert) formData.append('fitness_cert', fitness_cert);
    if (pollution_cert) formData.append('pollution_cert', pollution_cert);
    formData.append('vehicles_id', vehicles_id.toString());
    if (fitness_expiry) formData.append('fitness_expiry', fitness_expiry);
    if (insurance_expiry) formData.append('insurance_expiry', insurance_expiry);
    axios.post(`${global.api_url}/api/documents`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: AxiosResponse) => {
      if(response.status === 200) {
      _successCallBack(response.data);
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
       })
    .catch((error: AxiosError) => {
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }  else if( error.response?.status === 404)
        {
          _errorCallBack(error.response.data);
        }else {
        
        _errorCallBack(error);
      }
    });
  };
  export const getClients = (
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${global.api_url}/api/clients`, {
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else{
          toast.error("Server Error");
        }
      });
  };
  export const editVehicle = (
    number_plate:string,
    driver_id: number,
    model:string,
    make_year:string,
    owner_id: number,
    fuel_type:string,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void
  ) => {

  const encodedNumberPlate = encodeURIComponent(number_plate);
  const payload = {
    driver_id,
    model,
    make_year,
    owner_id,
    fuel_type,
  };
    axios.put(`${global.api_url}/api/vehicles/${encodedNumberPlate}`, payload, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response: AxiosResponse) => {
        if(response.status === 201) {
        _successCallBack(response.data);
      }
        else if(response.status === 200) 
      { _successCallBack(response.data);
      }
    })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        } else if (error.response?.status === 401) {
          localStorage.clear();
          toast.error("Login Expired")
        } else if (error.response?.status === 500){
          console.log(error);
          toast.error(error?.response?.data?.message);
        }
        else if( error.response?.status === 404)
          {
            _errorCallBack(error.response.data);
          }else {
          toast.error("Server Error");
        }
      });
  };
  export const editDocuments = (
    drivers_license: File | null,
    aadhar: File | null,
    reg_cert: File | null,
    insurance: File | null,
    fitness_cert: File | null,
    pollution_cert: File | null,
    vehicles_id: number,
    fitness_expiry: string | null,
    insurance_expiry: string | null,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void
  ) => {
    const formData = new FormData();
    if (drivers_license) formData.append('drivers_license', drivers_license);
    if (aadhar) formData.append('aadhar', aadhar);
    if (reg_cert) formData.append('reg_cert', reg_cert);
    if (insurance) formData.append('insurance', insurance);
    if (fitness_cert) formData.append('fitness_cert', fitness_cert);
    if (pollution_cert) formData.append('pollution_cert', pollution_cert);
    formData.append('vehicles_id', vehicles_id.toString());
    if (fitness_expiry) formData.append('fitness_expiry', fitness_expiry);
    if (insurance_expiry) formData.append('insurance_expiry', insurance_expiry);
    axios.post(`${global.api_url}/api/documents/edit`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: AxiosResponse) => {
      if(response.status === 200) {
      _successCallBack(response.data);
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
       })
    .catch((error: AxiosError) => {
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }  else if( error.response?.status === 404)
        {
          _errorCallBack(error.response.data);
        }else {
        
        _errorCallBack(error);
      }
    });
  };
export const getDriverName = (
  phone_number,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios
    .get(`${global.api_url}/api/users/?device_id=&user_phone=${phone_number}&country_code=91`, {
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
      else if(response.status === 201) { 
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      }
      else if  (error.response?.status === 401){
        localStorage.clear();
      }
      else if( error.response?.status === 404)
        {
          _errorCallBack(error.response.data);
        }
      else{
        toast.error("Server Error");
      }
    });
};

export const fetchClients = (
  successCallBack: (clients: any[]) => void,
  errorCallBack: (errorMessage: any) => void
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  axios.get(`${global.api_url}/api/clients`, { headers })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        const clients = response.data.message.clients;
        successCallBack(clients);
      } 
      else if(response.status === 201) {
         successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.error('Network Error Details:', error);
      if (error.response?.status === 400) {
          errorCallBack(error.response.data);
        } else if (error.response?.status === 401) {
          localStorage.clear();
          toast.error("Login Expired.");
        }
        else if( error.response?.status === 404)
          {
            errorCallBack(error.response.data);
          }
      else {
        toast.error("Server Error");
      }
    });
};

export const addClientUserMapping = (
  userId: any,
  clientId: any,
  successCallBack: (message: any) => void,
  errorCallBack: (errorMessage: any) => void
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const payload = {
    users_id:userId,
    clients_id:clientId,
  };
  axios.post(
    `${global.api_url}/api/client_users_mapping`,
    payload,
    { headers }
  )
  .then((response: AxiosResponse) => {
    if (response.status === 201) {
      successCallBack(response.data.message);
    } 
    else if (response.status === 200) {
      successCallBack(response.data.message);
    } 
  })
  .catch((error: AxiosError) => {
    console.error('Network Error Details:', error);
    if (error.response) {
      if (error.response.status === 400) {
        errorCallBack(error?.response?.data);
      } else if (error.response.status === 404){
        errorCallBack(error?.response?.data)
      }
      else if( error.response?.status === 404)
        {
          errorCallBack(error?.response?.data);
        }
      else if (error.response.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }
    } 
    else {
      toast.error("Server Error");
    }
  });
};

export const addVehicle = (
  model: string,
  makeYear: string,
  numberPlate: string,
  driverId: number,
  ownerId: number,
  fuelType: string,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void
) => {
  const payload = {
    model : model,
    make_year : makeYear,
    number_plate : numberPlate,
    driver_id : driverId,
    owner_id :ownerId,
    fuel_type : fuelType,
  };
   axios.post(`${global.api_url}/api/vehicles`,payload,
   {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response: AxiosResponse) => {
      if (response.status === 201) {
        _successCallBack(response.data.message);
      } 
      else if (response.status === 200) {
        _successCallBack(response.data.message);
      } 
      
    })
    .catch((error: any) => {
      if (error.response?.status === 400) {
        const errorMessage = error.response.data.message?.error || "An error occurred";
         _errorCallBack(errorMessage);
      } else if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }  else if( error.response?.status === 404)
        {
          _errorCallBack(error?.response?.data);
        }
      else {
        toast.error("Server Error");
      }
    });
};

export const fetchVendors = (
  successCallBack: (clients: any[]) => void,
  errorCallBack: (errorMessage: any) => void
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  axios.get(`${global.api_url}/api/vendors`, { headers })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        const vendors = response.data.message.vendors;
        successCallBack(vendors);
      }
      else if (response.status === 200) {
        successCallBack(response.data.message);
      } 
    })
    .catch((error: AxiosError) => {
      console.error('Network Error Details:', error);
      if (error.response?.status === 400) {
          errorCallBack(error.response.data);
        } else if (error.response?.status === 401) {
          localStorage.clear();
          toast.error("Login Expired.");
        } 
        else if( error.response?.status === 404)
          {
            errorCallBack(error?.response?.data);
          }
       else{
        toast.error("Server Error");
      }
});
};

export const deleteDocument = (
  vehicleDocumentId: number | null,
  vehicleId: number | null,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void
) => {
  const headers = {
    Authorization: `Bearer ${token}`
  };
  const data = {
    veh_documents_id: vehicleDocumentId,
    vehicles_id: vehicleId
  };
  axios
    .delete(`${global.api_url}/api/documents`, {
      headers: headers,
      data: data 
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
      else if (response.status === 200) {
        _successCallBack(response.data);
      } 
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }  else if( error.response?.status === 404)
        {
          _errorCallBack(error?.response?.data);
        }
      else {
        toast.error("Server Error");
      }
    });
};

export const addVendorUserMapping = (
  userId: number,
  vendorId: number,
  successCallBack: (message: string) => void,
  errorCallBack: (errorMessage: string) => void
) => {
  const payload = {
    users_id:userId,
    vendors_id:vendorId,
  };
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  axios.post(
    `${global.api_url}/api/vendor_users_mapping`,
    payload,
    { headers }
  )
  .then((response: AxiosResponse) => {
    if (response.status === 201) {
      successCallBack(response.data.message);
    } 
    else if (response.status === 200) {
      successCallBack(response.data.message);
    }
  })
  .catch((error: any) => {
    if (error.response) {
      if (error.response.status === 400) {
        errorCallBack(error.response.data);
      } else if (error.response.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }
    } 
    else if( error.response?.status === 404)
      {
        errorCallBack(error?.response?.data);
      }
    else {
      toast.error("Server Error");
    }
  });
};

export const getClientName = (
      users_id,
      _successCallBack: (response: any) => void,
      _errorCallBack: (errMessage: any) => void,
    ) => {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      axios
        .get(`${global.api_url}/api/client_users_mapping`, {
          params :{ users_id}, 
          headers: headers,
        })
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            _successCallBack(response.data);
          }
          else if (response.status === 200) {
            _successCallBack(response.data);
          } 
        })
        .catch((error: AxiosError) => {
          console.log('Error caught:', error);
          if (error.response?.status === 400) {
            _errorCallBack(error.response.data);
          }
          else if  (error.response?.status === 401){
            localStorage.clear();
          }
          else if( error.response?.status === 404)
            {
              _errorCallBack(error?.response?.data);
            }
          else{
            toast.error("Server Error");
          }
        });
    };
  


import axios, { AxiosResponse, AxiosError } from "axios";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface LoginInput {
    phone: string;
    password: string;
    language: string;
    country_code: string;
    otp?: string;
  }
  interface OTPInput{
    phone_number: string;
    from_register: number;
    country_code: string;
  }
  export const getLoginUser = (
    input: LoginInput,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void
  ) => {
    axios
      .post(`${global.api_url}/api/login`, input)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
          
        }
      })
      .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else{
          toast.error("Server Error");
        }
      })
  };
  export const getOTP = (
    input: OTPInput,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void
  ) => {
      axios.post(`${global.api_url}/api/generate_otp`, input)
      .then((response: AxiosResponse) => {
        if (response.status === 200){
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else{
          toast.error("Server Error");
        }
      });
        }
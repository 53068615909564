import React, {useState, useEffect} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, IconButton, Tooltip} from '@mui/material';
import ConfirmationPopup from '../../Components/ConfirmationModal/confirmation-modal.tsx';
import Heading from '../../Components/Heading/heading.tsx';
import UploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import '../../Components/Table/table-style.scss';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {validatePhoneNumber} from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import {editDriver , getDocuments ,addDocuments ,deleteDocument} from './action.ts';
import {validateDriverName} from '../../Utils/NameValidation/name-validation.tsx';
import {validateEmail} from '../../Utils/EmailValidation/email-validation.tsx';

interface DriverData {
  name: string;
  location: string;
  phone: string;
  whatsapp_number: string;
  email: string;
}
interface UploadedFile {
  location: string;
  id : number;
}
interface EditDriverModalProps {
  open: boolean;
  onClose: () => void;
  driverData: DriverData | null;
  driverId: number;
  fetchUsers: (page: number, pageSize: number, lead: string | null, blacklist: string | null, left: string | null, tabName: string) => void;
  page : number;
  pageSize : number;
}
const EditDriverModal: React.FC<EditDriverModalProps> = ({ open, onClose,driverData ,driverId ,fetchUsers ,page , pageSize }) => {
    const [file, setFile] = useState<{ [key: string]: File | null }>({});
    const [icons, setIcons] = useState<{ [key: string]: 'upload' | 'view' }>({
        aadhar: 'upload',
        drivinglicense: 'upload',
    });
    const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: File | null }>({
        drivinglicense: null,
        aadhar: null
    });
    const [documentList, setDocumentList] = useState<any[]>([]);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [documentToRemove, setDocumentToRemove] = useState<string | null>(null);
    const [documentDeleted, setDocumentDeleted] = useState(false);
    const [emailError, setEmailError] = useState<string>('');
    const [nameError , setNameError] = useState<string>('');
    const [whatsAppNumberError , setWhatsAppNumberError] = useState<string>('');
    const [locationError , setLocationError] = useState<string>('');
    const resetState = () => {
      setEmailError('');
      setNameError('');
      setWhatsAppNumberError('');
      setLocationError('');
    };
    const [documentLocation, setDocumentLocation] = useState<string | null>(null);
    const maxFileSize = 1 * 1024 * 1024; 
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
    const [uploadedFiles, setUploadedFiles] = useState<{ [key: string]: UploadedFile | null }>({
         drivinglicense: null,
         aadhar: null
    });
    const [documentState, setDocumentState] = useState(0);
    const [docTypeToDelete, setDocTypeToDelete] = useState<string | null>(null);
    const [countryCode, setCountryCode] = useState<string>('91');
    const [language, setLanguage] = useState<string>('EN');
    const [formValues, setFormValues] = useState({
      name: '',
      location: '',
      phone: '',
      whatsapp_number: '',
      email: '',
    });
    const fetchDocuments = () => {
      if (driverId) {
        getDocuments(
            '',
           driverId,
           (response: any) => {
                      const documentDetails = response.message?.user_documents|| [];
                      setDocumentList(documentDetails);
                      const updatedDocuments = documentDetails.map(doc => ({
                            ...doc,
                      }));
                     const newIcons: { [key: string]: string } = {
                           drivinglicense: 'upload',  
                           aadhar: 'upload',
                      };
                      documentDetails.forEach(doc => {
                           if (doc.status === 'uploaded') {
                                newIcons[doc.document_name.replace(' ', '')] = 'view';
                            }
                      });
                      setIcons(newIcons);
                      setDocumentState(2);
            },
           (errMessage: any) => {
                    toast.error(errMessage);
             }
        );
      }
   };
   const uploadFile = (type: string, file: File) => {
             if (file) {
                       addDocuments(
                        type === 'drivinglicense' ? file : null,
                        type === 'aadhar' ? file : null,
                         null,
                         null,
                         null,
                         null,
                         ' ',
                         null,
                         null,
                        driverId,
                        (response) => {
                              setUploadedFiles((prevFiles) => ({
                                   ...prevFiles,
                                   [type]: { location: response.message.location, id: response.message.id }
                              }));
                              setDocumentLocation(response.message.location);
                              setDocumentState(1);
                              toast.success(`${response.message.document_name} uploaded successfully`);
                        },
                        (errMessage: any) => {  
                            if (errMessage && errMessage.status === 400) {
                                   let allErrorMessages: string[] = [];
                                   if (typeof errMessage.message === 'string') {
                                            allErrorMessages.push(errMessage.message);
                                            setSelectedFiles((prevFiles) => ({
                                              ...prevFiles,
                                              [type]: file
                                             }));
                                            setIcons((prevIcons) => ({
                                              ...prevIcons,
                                              [type]: 'view'
                                            }));
                                            toast.error(allErrorMessages.join(" "));
                                    } else if (errMessage.message && typeof errMessage.message === 'object') {
                                            const errors = errMessage.message.errors || {};
                                            for (const [key, messages] of Object.entries(errors)) {
                                               if (Array.isArray(messages)) {
                                                   allErrorMessages.push(...messages);
                                                }
                                            }
                                            setSelectedFiles((prevFiles) => ({
                                               ...prevFiles,
                                               [type]: null
                                            }));
                                            setIcons((prevIcons) => ({
                                               ...prevIcons,
                                               [type]: 'upload'
                                            }));
                                            toast.error(allErrorMessages.join(" "));
                                     }
                            } else {
                            toast.error("An unexpected error occurred.");
                           }
                     }
               );
          }
    };
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const fileInput = event.target; 
        const file = fileInput.files?.[0] || null;
        setSelectedFiles((prevFiles) => ({
            ...prevFiles,
            [type]: file
        }));
        setIcons((prevIcons) => ({
            ...prevIcons,
            [type]: file ? 'view' : 'upload'
        }));
        if (file) {
            if (file.size > maxFileSize) {
                toast.error("File size must not exceed 1 MB");
                setSelectedFiles((prevFiles) => ({
                    ...prevFiles,
                    [type]: null
                }));
                setIcons((prevIcons) => ({
                    ...prevIcons,
                    [type]: 'upload'
                })); 
            }
            else if (!allowedFileTypes.includes(file.type)) {
                toast.error("File type not supported");
                setSelectedFiles((prevFiles) => ({
                    ...prevFiles,
                    [type]: null
                }));
                setIcons((prevIcons) => ({
                    ...prevIcons,
                    [type]: 'upload'
                }));
            }
           else if (driverId && file) {
                   uploadFile(type, file);
            }
           fileInput.value = '';
          }
    };
    const handleIconClick = (type: string, action: string) => {
        if (action === 'view' ) {
            if (documentState===1){
              const url = documentLocation;
              console.log(url);
              window.open(`${global.documents_image_url}${url}`, '_blank');      }
            else if(documentState===2 ){ 
               const document = documentList.find(doc => doc.document_name.replace(' ', '').toLowerCase() === type.toLowerCase() && doc.status === 'uploaded');
               const url = document.location;
               window.open(`${global.documents_image_url}${url}`, '_blank');      } 
            else{
             toast.error('Document not found.');
            }
        } 
        else if (action === 'remove') {
            setDocumentToRemove(type);
            setIsConfirmationOpen(true);
            setDocumentDeleted(true);      
        }
    };
    const handleConfirmDelete = () => {
        if (documentToRemove && driverId ) {
            if (documentState===2) {
              const document = documentList.find(doc => 
                doc.document_name.replace(' ', '').toLowerCase() === documentToRemove.toLowerCase() &&
                doc.status === 'uploaded'
              ); 
              const documentId = document.id; 
                  if(documentId){
                      deleteDocument(
                         documentId,
                         null,
                         null,
                         driverId, 
                        (response) => {
                              toast.success(response?.message);
                              setSelectedFiles((prevFiles) => ({
                                  ...prevFiles,
                                  [documentToRemove]: null
                              }));
                              setIcons((prevIcons) => ({
                                  ...prevIcons,
                                  [documentToRemove]: 'upload'
                              }));
                              setDocumentLocation('');
                        },
                        (errorMessage) => {   
                             toast.error(errorMessage.data.message);
                        }
                      );
                   }}
                else {
                  deleteDocument(
                    uploadedFiles[documentToRemove]?.id,
                    null,
                    null,
                    driverId, 
                    (response) => {
                        toast.success(response?.message);
                        setSelectedFiles((prevFiles) => ({
                            ...prevFiles,
                            [documentToRemove]: null
                        }));
                        setIcons((prevIcons) => ({
                            ...prevIcons,
                            [documentToRemove]: 'upload'
                        }));
                        setDocumentLocation('');
                    },
                    (errorMessage) => {   
                        toast.error(errorMessage.data.message);
                    }
                  );
                  }
                } 
            else {
              toast.error('Document not found.');
            }
            setDocumentToRemove(null);
            setIsConfirmationOpen(false);
            setDocumentState(0);
   }
   const renderFileInput = (label: string, type: string) => (
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ fontSize: '17px', margin: 0, marginRight: '10px' }}>{label}</p>
          <input
            accept="*"
            style={{ display: 'none' }}
            id={`${type}-file`}
            type="file"
            onChange={(e) => handleFileChange(e, type)}
            />
       <label htmlFor={`${type}-file`}>
      <Tooltip title="Upload File">
        <IconButton color="primary" component="span">
          {icons[type] === 'upload' && <UploadIcon />}
        </IconButton>
      </Tooltip>
      </label>
      {icons[type] === 'view' && (
        <>
        <Tooltip title="View File">
          <span
            style={{ cursor: 'pointer', color: 'blue', marginRight: '10px' }}
            onClick={() => handleIconClick(type, 'view')}
          >
            View
          </span>
        </Tooltip>
        <Tooltip title="Remove File">
          <IconButton sx={{ color: 'red' }} component="span" onClick={() => handleIconClick(type, 'remove')}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </>
      )}
      </div>
    );
    useEffect(() => {
      if (open && driverId) {
        fetchDocuments();
      }
      if (driverData) {
        setFormValues({
          name: driverData.name || '',
          location: driverData.location || '',
          phone: driverData.phone || '',
          whatsapp_number: driverData.whatsapp_number || '',
          email: driverData.email || '',
        });
      }
    }, [driverData]);
    const handleCancelDelete = () => {
      setDocumentToRemove(null);
      setIsConfirmationOpen(false);
    };
    const handleSave = () => {
      if(nameError||emailError||whatsAppNumberError||locationError){
        toast.error("Please fix the errors");
        return;
      }
      if (
        formValues.name === driverData?.name &&
        formValues.whatsapp_number === driverData?.whatsapp_number &&
        formValues.email === driverData?.email 
      ) {
        handleClose();
        return;
      }
      else {
        editDriver(
           formValues.name, 
           formValues.email, 
           formValues.whatsapp_number, 
           countryCode, 
           language, 
           driverId,
           (response) => {
              toast.success(response.message); 
           },
           (error) => {
              toast.error(error);
           }
        );
        onClose();
        setTimeout(() => {
           fetchUsers(page, pageSize, null, null, null, 'driver');
        }, 2000);
      }
    };
    const handleClose = () => {
        onClose();
        resetState();
    };
    const handleNameChange = (event) => {
      const { value } = event.target;
      if (value === '') {
        setNameError('');
      } else if (validateDriverName(value)) {
        setNameError('');
      } else {
        setNameError('Characters are allowed');
      }
      setFormValues(prev => ({
        ...prev,
        name: value,
      }));
    };
    const handleEmailChange = (event) => {
        const { value } = event.target;
        setEmailError('');
        setFormValues(prev => ({
          ...prev,
          email: value,
      }));
    };
    const handleEmailBlur = () => {
        if (formValues.email === '') {
          setEmailError('');
        } else if (validateEmail(formValues.email)) {
          setEmailError('');
        } else {
          setEmailError('Please enter a valid email address.');
        }
    };
    const handlePhoneNumberBlur = () => {
      if (formValues.whatsapp_number === '') {
        setWhatsAppNumberError('');
      } else if (formValues.whatsapp_number.length>=1&&formValues.whatsapp_number.length<10) {
        setWhatsAppNumberError('Please enter a 10 digit number');
      } 
    };
    const handleNameBlur = () => {
        if (nameError=== '') {
          setNameError('');
        } else  {
          setNameError(nameError);
        } 
    };
    const handleLocationBlur = () => {
      if (locationError=== '') {
        setLocationError('');
      } else  {
        setLocationError(locationError);
      } 
    };
    const handleLocationChange = (event) => {
      const { value } = event.target;
      if (value === '') {
        setLocationError('');
      } else if (validateDriverName(value)) {
        setLocationError('');
      } else {
        setLocationError('Characters are allowed');
      }
      setFormValues(prev => ({
        ...prev,
        location: value,
      }));
    };  
    const handlePhoneNumberChange = (event) => {
        const { value } = event.target;
        if(value==='')
        {
          setWhatsAppNumberError("");
        }
        else{
          const { errorMessage } = validatePhoneNumber(value);
          setWhatsAppNumberError(errorMessage);}
          setFormValues(prev => ({
             ...prev,
             whatsapp_number: value,
        }));
    };  
    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: '800px', height: 'auto' } }}>
        <DialogTitle>
          <div>
            <Heading title={`Edit Driver - ${formValues.phone}`} closeIcon="true" onClose={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', margin: '10px 40px' }}>
            <TextField
              variant="outlined"
              size="small"
              label="Name"
              name="name"
              value={formValues.name}
              onChange={(e) => handleNameChange(e)}
              onBlur={handleNameBlur}
              error={!!nameError}
              helperText={nameError}
              style={{ width: '225px' }}
            />
            <TextField
              variant="outlined"
              size="small"
              label="WhatsApp Number"
              name="whatsapp_number"
              value={formValues.whatsapp_number}
              onChange={(e) => handlePhoneNumberChange(e)}
              onBlur={handlePhoneNumberBlur}
              error={!!whatsAppNumberError}
              helperText={whatsAppNumberError}
              style={{ width: '225px' }}
            />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', margin: '10px 40px' }}>
            <TextField
              variant="outlined"
              size="small"
              label="Email"
              name="email"
              value={formValues.email}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              error={!!emailError}
              helperText={emailError}
              style={{ width: '225px' }}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Location"
              name="location"
              value={formValues.location}
              onChange={(e) => handleLocationChange(e)}
              onBlur={handleLocationBlur}
              error={!!locationError}
              helperText={locationError}
              style={{ width: '225px' }}
            />
            </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '-10px' }}>
             <div style={{ marginLeft: '40px' }}>
                 {renderFileInput('Aadhar', 'aadhar')}
             </div>
             <div style={{ marginLeft: '100px' }}>
                 {renderFileInput('License', 'drivinglicense')}
             </div>
         </div>
        </DialogContent>
        <DialogActions  style={{ display: 'flex', justifyContent: 'center' }}>
            <Button className='custom-button' onClick={handleSave}>Save</Button>
            <Button className='custom-cancel-button' onClick={handleClose}>Cancel</Button>
        </DialogActions>
        <ConfirmationPopup
                    open={isConfirmationOpen}
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                    contentToDisplay = ""
                    title="Confirm Deletion"
                    message={`Are you sure you want to delete the document?`}
        />
      </Dialog>
    );
  };
  export default EditDriverModal;
import React, { useState, useRef, useEffect } from 'react';
import { Box, Container, Typography, IconButton, Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload'; 
import DeleteIcon from '@mui/icons-material/Delete';
import Heading from '../../Components/heading.tsx';
import { getDocuments, addDocuments, deleteDocument } from './action.ts';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = {
  statusContainer: {
    backgroundColor: '#f0f0f0', 
    paddingBottom: '16px',
    borderRadius: '8px',
    width: '100%',
    marginTop: '30px',
  },
  documentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '10px',
    marginBottom: '10px',
  },
  documentContainer: {
    backgroundColor: '#f0f0f0',
    paddingRight: '0px',
    paddingLeft: '0px',
  },
  inputFile: {
    display: 'none',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  viewLabel: {
    marginRight: '8px',
    color: '#02e8ee',
    cursor: 'pointer',
  },
  iframeContainer: {
    width: '100vw', 
    height: '100vh',
    border: 'none',
    overflow: 'hidden',
  },
  backButton: {
    marginTop: '25px',
    marginLeft:'5px',
    color: 'black',
  }
};

const DocumentsScreen: React.FC = () => {
  const documentMapping: { [key: string]: string } = {
    'aadhar': 'Aadhar Card',
    'driving license': 'Driving License',
    'registration certificate': 'Registration Certificate',
    'pollution certificate': 'Pollution Certificate',
    'fitness certificate': 'Fitness Certificate',
    'insurance': 'Insurance',
  };

  const [uploadedUserFiles, setUploadedUserFiles] = useState<{ [key: string]: { name: string; location: string; id: number | null} | null }>({});
  const [uploadedVehicleFiles, setUploadedVehicleFiles] = useState<{ [key: string]: { name: string; location: string; id: number | null } | null }>({});
  const [userId, setUserId] = useState<{ [key: string]: { name: string; location: string } | null }>({});
  const [isViewingDocument, setIsViewingDocument] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');
  const fileInputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});
  const handleFileChange = (document: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (document === 'aadhar' || document === 'driving license') {
        setUploadedUserFiles((prev) => ({ ...prev, [document]: { name: file.name, location: '', id: null } }));
      } else {
        setUploadedVehicleFiles((prev) => ({ ...prev, [document]: { name: file.name, location: '', id: null } }));
      }
      handleUploadClick(document, file);
    }
  };
  const handleUploadClick = (document: string, file: File) => {
    const isUserDocument = document === 'driving license' || document === 'aadhar';
    if (isUserDocument) {
      addDocuments(
        document === 'driving license' ? file : null,
        document === 'aadhar' ? file : null,
        null, 
        null, 
        null, 
        null,
        '',  
        Number(userId),
        (response) => {
          setUploadedUserFiles((prevFiles) => ({
            ...prevFiles,
            [document]: { location: response.message.location, id: response.message.id }
          }));
          toast.success(`${documentMapping[document]} uploaded successfully`);
        },
        (errMessage) => {
          toast.error(errMessage?.message || "An unexpected error occurred.");
        }
        );
      } else {
        addDocuments(
          null,
          null,
          document === 'registration certificate' ? file : null,
          document === 'insurance' ? file : null,
          document === 'fitness certificate' ? file : null,
          document === 'pollution certificate' ? file : null,
          Number(vehicleId),
          null,  
          (response) => {
            setUploadedVehicleFiles((prevFiles) => ({
              ...prevFiles,
              [document]: { location: response.message.location, id: response.message.id }
            }));
            toast.success(`${documentMapping[document]} uploaded successfully`);
          },
          (errMessage) => {
            toast.error(errMessage?.message || "An unexpected error occurred.");
          }
        );
      }
  };
  const handleDelete = (documentKey: string) => {
    const usersDocumentDetails = uploadedUserFiles[documentKey];
    const vehiclesDocumentDetails = uploadedVehicleFiles[documentKey];
    if (!usersDocumentDetails?.id && !vehiclesDocumentDetails?.id) {
      toast.error('Document not found or invalid.');
      return;
    }
    const userDocumentId = usersDocumentDetails?.id;
    const vehicleDocumentId = vehiclesDocumentDetails?.id;
    deleteDocument(
      userDocumentId,
      vehicleDocumentId,
      vehicleId,
      userId,
      (response) => {
        toast.success(response?.message);
        setUploadedUserFiles((prev) => ({ ...prev, [documentKey]: null }));
        setUploadedVehicleFiles((prev) => ({ ...prev, [documentKey]: null }));
      },
      (errorMessage) => {
        toast.error(errorMessage.data.message);
      }
    );};
    const handleBack = () => {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ action: "navigateBackToApp" })
      );
    };
    const handleDocumentBack = () => {
      setIsViewingDocument(false);
    };
    const params = new URLSearchParams(window.location.search);
    const vehicleId = params.get('vehicle_id');
    useEffect(() => {
      getDocuments(
        vehicleId,
        (response: any) => {
          const userDocumentDetails = response.message?.user_documents || [];
          const uploadedUserDocuments: { [key: string]: { id: number; name: string; location: string } } = {};
          const vehicleDocumentDetails = response.message?.vehicle_documents || [];
          const uploadedVehicleDocuments: { [key: string]: { id: number; name: string; location: string } } = {};
          userDocumentDetails.forEach(doc => {
            if (doc.status === 'uploaded') {
              uploadedUserDocuments[doc.document_name] = {
                id: doc.id,  
                name: documentMapping[doc.document_name] || doc.document_name,
                location: doc.location,
              };
            }
          });
          vehicleDocumentDetails.forEach(doc => {
            if (doc.status === 'uploaded') {
              uploadedVehicleDocuments[doc.document_name] = {
                id: doc.id, 
                name: documentMapping[doc.document_name] || doc.document_name,
                location: doc.location,
              };
            }
          });
          setUserId(response.message?.user_documents[0]?.users_id || response.message?.vehicle_documents[0]?.users_id);
          setUploadedUserFiles(uploadedUserDocuments);
          setUploadedVehicleFiles(uploadedVehicleDocuments);
        },
        (errMessage: any) => {
          toast.error(errMessage);
        }
      );
    }, [vehicleId]);
    const handleViewClick = (document: string, location: string) => {
      const url = `${global.documents_image_url}${location}`;
      console.log(url);
      setDocumentUrl(url);
      setIsViewingDocument(true);
    };
  return (
    <Container style={styles.documentContainer}>
      {isViewingDocument ? (
        <Box>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={handleDocumentBack} style={styles.backButton}>
            <ArrowBackIcon />
          </IconButton>
          <iframe src={documentUrl} style={styles.iframeContainer} title="Document Viewer" />
        </Box>
      ) : (
        <Box>
          <Box style={styles.statusContainer}>
            <Heading title="Documents" onBackClick={handleBack} />
          </Box>
          <Box sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
            {Object.keys(documentMapping).map((key, index) => (
              <Box key={index} style={styles.documentBox}>
                <Typography variant="body1">{documentMapping[key]}</Typography>
                <div style={styles.actionContainer}>
                  <input
                    ref={(el) => (fileInputRefs.current[key] = el)}
                    type="file"
                    style={styles.inputFile}
                    onChange={handleFileChange(key)}
                  />
                  {!uploadedUserFiles[key] && !uploadedVehicleFiles[key] ? (
                    <IconButton onClick={() => fileInputRefs.current[key]?.click()}>
                      <UploadIcon />
                    </IconButton>
                  ) : (
                    <div style={styles.actionContainer}>
                      <Typography variant="body1" style={styles.viewLabel} onClick={() => handleViewClick(key, uploadedUserFiles[key]?.location || uploadedVehicleFiles[key]?.location)}>
                        View
                      </Typography>
                      <IconButton onClick={() => handleDelete(key)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default DocumentsScreen;

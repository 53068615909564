import axios, { AxiosResponse, AxiosError } from "axios";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const storedUserDetails = localStorage.getItem("userdetails");
const token = storedUserDetails ? JSON.parse(storedUserDetails).token.replace(/^"(.*)"$/, '$1') : null;
export const getClients = (
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
  ) => {
    const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
    const headers = {
      Authorization: `Bearer ${userDetailsFromStorage.token}`
    };
    axios
      .get(`${global.api_url}/api/clients`, {
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
        else if (response.status === 201){
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else if (error.response?.status === 404){
          _errorCallBack(error.response.data);
        }
        else{
          toast.error("Server Error");
        }
      }
    );
  };

  export const getTrips = (
    per_page_records: number,
    page_number: number,
    from_date: string,
    to_date: string,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
    vehicle_id?: any,
    client_name?:any
  ) => {
    const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
    const headers = {
      Authorization: `Bearer ${userDetailsFromStorage.token}`
    };
    axios
      .get(`${global.api_url}/api/get_trips`, {
        params: {
          per_page_records,
          page_number,
          from_date,
          to_date,
          vehicle_id,
          client_name
        },
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
        else if (response.status === 201){
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else if (error.response?.status === 404){
          _errorCallBack(error.response.data);
        }
        else{
          toast.error("Server Error");
        }
      });
  };
  export const getVehicle = (
    per_page_records:number,
    page_number:number,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
    client_name ?: string,
  ) => {
    const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
    const headers = {
      Authorization: `Bearer ${userDetailsFromStorage.token}`
    };
    axios
      .get(`${global.api_url}/api/vehicles`, {params:{per_page_records, page_number, client_name},
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
        else if (response.status === 201){
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else if (error.response?.status === 404){
          _errorCallBack(error.response.data);
        }
        else{
          toast.error("Server Error");
        }
      });
  };
  
  export const getFleetCount = (
    from_date:any,
    to_date:any,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
  ) => {
    const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
    const headers = {
      Authorization: `Bearer ${userDetailsFromStorage.token}`
    };
    axios
      .get(`${global.api_url}/api/dashboard/fleet_count?from_date=${from_date}&to_date=${to_date}`, {
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
        else if (response.status === 201){
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else if (error.response?.status === 404){
          _errorCallBack(error.response.data);
        }
        else{
          toast.error("Server Error");
        }
      });
  };
  export const getAverageKmPerVehicle = ( from_date:any,
    to_date:any,
    client_id:any, 
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void) => {
      const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
      const headers = {
        Authorization: `Bearer ${userDetailsFromStorage.token}`
      };
      axios
        .get(`${global.api_url}/api/dashboard/distance?from_date=${from_date}&to_date=${to_date}&client_id=${client_id}`, {
          headers: headers,
        })
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            _successCallBack(response.data);
          }
          else if (response.status === 201){
            _successCallBack(response.data);
          }
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 400) {
            _errorCallBack(error.response.data);
          }
          else if  (error.response?.status === 401){
            localStorage.clear();
          }
          else if (error.response?.status === 404){
            _errorCallBack(error.response.data);
          }
          else{
            toast.error("Server Error");
          }
        });
  }

  export const getTripStatus = ( 
    client_id:any,
    fuel_type,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void) => {
      const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
      const headers = {
        Authorization: `Bearer ${userDetailsFromStorage.token}`
      };
      axios
        .get(`${global.api_url}/api/dashboard/trip_status?client_id=${client_id}&fuel_type=${fuel_type}`,{
          headers: headers,
        })
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            _successCallBack(response.data);
          }
          else if (response.status === 201){
            _successCallBack(response.data);
          }
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 400) {
            _errorCallBack(error.response.data);
          }
          else if  (error.response?.status === 401){
            localStorage.clear();
          }
          else if (error.response?.status === 404){
            _errorCallBack(error.response.data);
          }
          else{
            toast.error("Server Error");
          }
        });
  }
import React from 'react';
import { TextField, InputLabel } from '@mui/material';

interface DatePickerProps {
    id: string;
    name: string;
    value: string;
    label: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    minDate?: Date;
    maxDate?: Date;
    labelMargin?:any;
}

const DatePicker: React.FC<DatePickerProps> = ({
    id,
    name,
    value,
    label,
    onChange,
    minDate,
    maxDate,
    labelMargin
}) => {
    const minDateString = minDate ? minDate.toISOString().split('T')[0] : undefined;
    const maxDateString = maxDate ? maxDate.toISOString().split('T')[0] : undefined;

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
            <InputLabel htmlFor={id} style={{ marginRight: labelMargin || '8px', color: 'black' }}>{label}</InputLabel>
            <TextField
                type="date"
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                variant="outlined"
                style={{ backgroundColor: 'white', width: '150px', height: '40px' }}
                InputProps={{
                    style: { height: '40px' }
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    ...(minDateString && { min: minDateString }),
                    ...(maxDateString && { max: maxDateString })
                }}
            />
        </div>
    );
};

export default DatePicker;

import React from 'react';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import './heading.scss';
import Typography from '@mui/material/Typography';

interface HeadingProps {
  title: string;
  closeIcon: string;
  onClose?: () => void;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}

const Heading: React.FC<HeadingProps> = ({ title, closeIcon, onClose, leftContent, rightContent }) => {
  return (
    <div >
  <div className='heading title-align' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {leftContent && <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>{leftContent}</div>}
        </div>
        <Typography fontWeight="bold" variant='h6' component="div" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', textAlign: 'center' }}>
          {title}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {rightContent}
        {closeIcon !== 'false' && (
            <IconButton onClick={onClose} aria-label="close">
              <CancelIcon />
            </IconButton>
            )}
          </div>
      </div>
    </div>
    // </div>
  );
};

export default Heading;

export const validateIFSC = (ifsc: string): string => {
    const length = ifsc.length;
    if (length === 0) return ''; 
    if (length >= 1 && !/^[A-Z]{1}/.test(ifsc)) {
      return 'The first character must be an alphabet';
    }
    if (length >= 2 && !/^[A-Z]{2}/.test(ifsc)) {
      return 'The second character must be an alphabet';
    }
    if (length >= 3 && !/^[A-Z]{3}/.test(ifsc)) {
      return 'The third character must be an alphabet';
    }
    if (length >= 4 && !/^[A-Z]{4}/.test(ifsc)) {
      return 'The fourth character must be an alphabet';
    }
    if (length >= 5 && ifsc[4] !== '0') {
      return 'The fifth character must be zero (0)';
    }
    if (length >= 6 && !/^[A-Z]{4}0[A-Z0-9]{1}/.test(ifsc)) {
      return 'The sixth character must be an alphabet or digit';
    }
    if (length >= 7 && !/^[A-Z]{4}0[A-Z0-9]{2}/.test(ifsc)) {
      return 'The seventh character must be an alphabet or digit';
    }
    if (length >= 8 && !/^[A-Z]{4}0[A-Z0-9]{3}/.test(ifsc)) {
      return 'The eighth character must be an alphabet or digit';
    }
    if (length >= 9 && !/^[A-Z]{4}0[A-Z0-9]{4}/.test(ifsc)) {
      return 'The ninth character must be an alphabet or digit';
    }
    if (length >= 10 && !/^[A-Z]{4}0[A-Z0-9]{5}/.test(ifsc)) {
      return 'The tenth character must be an alphabet or digit';
    }
    if (length >= 11 && !/^[A-Z]{4}0[A-Z0-9]{6}/.test(ifsc)) {
      return 'The eleventh character must be an alphabet or digit';
    }
    if (length > 11) {
      return 'IFSC code must be exactly 11 characters';
    }
    return ''; 
};
  
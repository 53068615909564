const formatDate = (dateString: string): string => {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return ''; 
  }
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours < 12 ? 'AM' : 'PM';
  hours = hours % 12;
  hours = hours ? hours : 12; 
  return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
};
export default formatDate;

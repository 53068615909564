export const validateCin = (cin: string): string => {
  const length = cin.length;
  if (length === 0) return ''; 
  if (length >= 1 && !/^[UL]{1}/.test(cin)) {
    return 'First character must be U or L';
  }
  if (length >= 2 && !/^[UL]{1}\d{1}/.test(cin)) {
    return 'Second character must be a digit';
  }
  if (length >= 3 && !/^[UL]{1}\d{2}/.test(cin)) {
    return 'Third character must be a digit';
  }
  if (length >= 4 && !/^[UL]{1}\d{3}/.test(cin)) {
    return 'Fourth character must be a digit';
  }
  if (length >= 5 && !/^[UL]{1}\d{4}/.test(cin)) {
    return 'Fifth character must be a digit';
  }
  if (length >= 6 && !/^[UL]{1}\d{5}/.test(cin)) {
    return 'Sixth character must be a digit';
  }
  if (length >= 7 && !/^[UL]{1}\d{5}[A-Za-z]{1}/.test(cin)) {
    return 'Seventh character must be an alphabet';
  }
  if (length >= 8 && !/^[UL]{1}\d{5}[A-Za-z]{2}/.test(cin)) {
    return 'Eighth character must be an alphabet';
  }
  if (length >= 9 && !/^[UL]{1}\d{5}[A-Za-z]{2}\d{1}/.test(cin)) {
    return 'Ninth character must be a digit';
  }
  if (length >= 10 && !/^[UL]{1}\d{5}[A-Za-z]{2}\d{2}/.test(cin)) {
    return 'Tenth character must be a digit';
  }
  if (length >= 11 && !/^[UL]{1}\d{5}[A-Za-z]{2}\d{3}/.test(cin)) {
    return 'Eleventh character must be a digit';
  }
  if (length >= 12 && !/^[UL]{1}\d{5}[A-Za-z]{2}\d{4}/.test(cin)) {
    return 'Twelfth character must be a digit';
  }
  if (length >= 13 && !/^[UL]{1}\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{1}/.test(cin)) {
    return 'Thirteenth character must be an alphabet';
  }
  if (length >= 14 && !/^[UL]{1}\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{2}/.test(cin)) {
    return 'Fourteenth character must be an alphabet';
  }
  if (length >= 15 && !/^[UL]{1}\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{3}/.test(cin)) {
    return 'Fifteenth character must be an alphabet';
  }
  if (length >= 16 && !/^[UL]{1}\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{3}\d{1}/.test(cin)) {
    return 'Sixteenth character must be a digit';
  }
  if (length >= 17 && !/^[UL]{1}\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{3}\d{2}/.test(cin)) {
    return 'Seventeenth character must be a digit';
  }
  if (length >= 18 && !/^[UL]{1}\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{3}\d{3}/.test(cin)) {
    return 'Eighteenth character must be a digit';
  }
  if (length >= 19 && !/^[UL]{1}\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{3}\d{4}/.test(cin)) {
    return 'Nineteenth character must be a digit';
  }
  if (length >= 20 && !/^[UL]{1}\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{3}\d{5}/.test(cin)) {
    return 'Twentieth character must be a digit';
  }
  if (length >= 21 && !/^[UL]{1}\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{3}\d{6}/.test(cin)) {
    return 'Twenty-first character must be a digit';
  }
  if (length > 21) {
    return 'CIN must be exactly 21 characters';
  }
  return ''; 
};

import React from 'react';
import Pagination from '@mui/material/Pagination';
interface NewpaginationProps {
  rows: any;
  pageSize: number;
  page: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void;
 }
const Newpagination: React.FC<NewpaginationProps> = ({ rows, pageSize, page, handlePageChange }) => {
  return (
    <Pagination
      size="small"
      count ={Math.max(1, Math.ceil(rows / pageSize))}
      page={page + 1}
      onChange={handlePageChange}
      color="primary"
    />
  );
};
export default Newpagination;
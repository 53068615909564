import React, {useEffect,useState} from 'react';
import Heading from '../../Components/Heading/heading.tsx';
import '../../Components/Button/button-style.scss';
import {Dialog, DialogContent, DialogTitle, TextField, Box} from '@mui/material';

interface BankDetails {
    bank_name: string;
    branch_name: string;
    account_name : string;
    account_number: string;
    ifsc: string;
  }
interface BankDetailsModalProps {
  bankDetailsModalOpen: boolean;
  onClose: () => void;
  selectedBankDetails :BankDetails; 
}

const BankDetailsModal = ({ bankDetailsModalOpen, onClose , selectedBankDetails }: BankDetailsModalProps) => {
   
    const [bankName, setBankName] = useState('');
    const [branchName, setBranchName] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [ifsc, setIfsc] = useState('');
    useEffect(() => {
      if (selectedBankDetails) {
        setBankName(selectedBankDetails.bank_name || '');
        setBranchName(selectedBankDetails.branch_name || '');
        setAccountName(selectedBankDetails.account_name || '');
        setAccountNumber(selectedBankDetails.account_number || '');
        setIfsc(selectedBankDetails.ifsc || '');
      }
    }, [selectedBankDetails]);
    
  return (
    <Dialog
      open={bankDetailsModalOpen}
      onClose={onClose}
      fullWidth
      maxWidth={false}
      PaperProps={{
        sx: {
          width: '450px', 
          height: 'auto', 
        }
      }}
    >
      <DialogTitle>
        <div style={{ margin: '0px 0px 0px 0px' }}>
          <Heading title="Bank Details" closeIcon="true" onClose={onClose} />
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
            <p style={{ fontSize: '17px' }}>Bank Name</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="name"
              value={bankName}
              disabled
           />
          </Box>
        </div>
        <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
          <p style={{ fontSize: '17px' }}>Branch Name</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="pincode"
              value = {branchName}
              disabled
             
            />
          </Box>
        </div>
       <div style={{ marginLeft: '-10px', marginTop: '7px' }}>
          <p style={{ fontSize: '17px' }}>Account Number</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
        <TextField
              variant="outlined"
              size="small"
              type="text"
              name="mobileNumber"
              value={ accountNumber}
             
              disabled
              
            />
        </div>
        <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}>Account Name</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
           <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="email"
              value={accountName}
              disabled
              
            />
          </Box>
         </div>
         <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}>IFSC</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
           <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="email"
              value={ifsc}
              disabled
            />
          </Box>
         </div>
      </DialogContent>
    </Dialog>
  );
};

export default BankDetailsModal;

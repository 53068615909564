import React from 'react';
import { Box, Container, Typography } from '@mui/material';

import Heading from '../../Components/heading.tsx';
import 'react-toastify/dist/ReactToastify.css';

const styles = {
    header: {
        marginTop: '30px',
      },
  statusContainer: {
    paddingBottom: '16px',
    borderRadius: '8px',
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  launchingSoonText: {
    textAlign: 'center',
    fontSize: '50px',
    color: '#18bdde',
    marginTop:'250px'
  },
};

const OffersScreen: React.FC = () => {
  const handleBack = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ action: "navigateBackToApp" })
    );
  };

  return (
    <Container style={styles.container}>
    <Box style={styles.header}>
      <Heading title="Offers" onBackClick={handleBack} />
    </Box>
    <Box style={styles.statusContainer}>
      <Typography style={styles.launchingSoonText}>
        Launching Soon
      </Typography>
    </Box>
  </Container>
  );
};

export default OffersScreen;

import axios, { AxiosResponse, AxiosError } from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const getCompanies = (
  per_page_records:any,
  page_number:any,
  companies_id: string | null, 
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
  ) => {
    const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
    console.log(userDetailsFromStorage);
  const headers = {
    Authorization: `Bearer ${userDetailsFromStorage.token}`, 
  };
  axios
    .get(`${global.api_url}/api/companies`, {
      params: {per_page_records, page_number,companies_id }, 
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200 || response.status === 201) {
        _successCallBack(response.data);
      } else {
        _errorCallBack("Unexpected status code: " + response.status);
      }
    })
    .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        } else if (error.response?.status === 404) {
            console.log(error.response.data);
          _errorCallBack(error.response.data);
        }else if (error.response?.status === 401) {
          localStorage.clear();
        } 
      });
};

export const editCompany = (
    company_id: number,
    name: string | null,
    email: string | null,
    password: string | null,
    phone: string | null,
    address: string | null,
    pincode: string | null,
    account_name: string | null,
    account_number: string | null,
    bank_name: string | null,
    branch_name: string | null,
    ifsc: string | null,
    contact1_name: string | null,
    contact1_email: string | null,
    contact1_phone: string | null,
    contact1_designation: string | null,
    contact2_name: string | null,
    contact2_email: string | null,
    contact2_phone: string | null,
    contact2_designation: string | null,
    contact3_name: string | null,
    contact3_email: string | null,
    contact3_phone: string | null,
    contact3_designation: string | null,
    pan: string | null,
    gst: string | null,
    tan: string | null,
    state: string | null,
    cin: string | null,
    type: string | null,
    brands: string[] | null,
    selectedFiles: {
      logo?: File | null;
      pan?: File | null;
      tan?: File | null;
      gst?: File | null;
      cancelled_cheque?: File | null;
    },
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
    ) => {
      const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
      console.log(userDetailsFromStorage);
    const headers = {
      Authorization: `Bearer ${userDetailsFromStorage.token}`,
      'Content-Type': 'multipart/form-data', 
    };
    const formData = new FormData();
    formData.append('name', name || '');
    formData.append('email', email || '');
    formData.append('password', password || '');
    formData.append('phone', phone || '');
    formData.append('address', address || '');
    formData.append('pincode', pincode || '');
    formData.append('account_name', account_name || '');
    formData.append('account_number', account_number || '');
    formData.append('bank_name', bank_name || '');
    formData.append('branch_name', branch_name || '');
    formData.append('ifsc', ifsc || '');
    formData.append('contact1_name', contact1_name || '');
    formData.append('contact1_email', contact1_email || '');
    formData.append('contact1_phone', contact1_phone || '');
    formData.append('contact1_designation', contact1_designation || '');
    formData.append('contact2_name', contact2_name || '');
    formData.append('contact2_email', contact2_email || '');
    formData.append('contact2_phone', contact2_phone || '');
    formData.append('contact2_designation', contact2_designation || '');
    formData.append('contact3_name', contact3_name || '');
    formData.append('contact3_email', contact3_email || '');
    formData.append('contact3_phone', contact3_phone || '');
    formData.append('contact3_designation', contact3_designation || '');
    formData.append('pan', pan || '');
    formData.append('gst', gst || '');
    formData.append('tan', tan || '');
    formData.append('state', state || '');
    formData.append('cin', cin || '');
    formData.append('type', type || '');
    if (brands) {
      brands.forEach((brand, index) => {
        formData.append(`brands[${index}]`, brand);
      });
    }
    if (selectedFiles.logo) {
      formData.append('logo', selectedFiles.logo);
    }
    if (selectedFiles.pan) {
      formData.append('pan_file', selectedFiles.pan); 
    }
    if (selectedFiles.tan) {
      formData.append('tan_file', selectedFiles.tan); 
    }
    if (selectedFiles.gst) {
      formData.append('gst_file', selectedFiles.gst); 
    }
    if (selectedFiles.cancelled_cheque) {
      formData.append('cancelled_cheque', selectedFiles.cancelled_cheque); 
    }
    axios
      .post(`${global.api_url}/api/edit_companies/${company_id}`, formData, { headers })
      .then((response: AxiosResponse) => {
        if (response.status === 200 || response.status === 201) {
          _successCallBack(response.data); 
        }
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data); 
        } else if (error.response?.status === 404) {
          _errorCallBack(error.response.data); 
        } else if (error.response?.status === 401) {
          localStorage.clear(); 
        } else {
          toast.error('Server Error'); 
        }
      });
};
  
export const addCompany = (
  name: string | null,                             
  email: string | null,                            
  password: string | null, 
  password_confirmation:string|null,                        
  phone: string | null,                           
  address: string | null,                          
  pincode: string | null,                          
  account_name: string | null,                     
  account_number: string | null,                   
  bank_name: string | null,                        
  branch_name: string | null,                      
  ifsc: string | null,                             
  contact1_name: string | null,                    
  contact1_email: string | null,                   
  contact1_phone: string | null,                  
  contact1_designation: string | null,             
  contact2_name: string | null,                   
  contact2_email: string | null,                  
  contact2_phone: string | null,                  
  contact2_designation: string | null,             
  contact3_name: string | null,                    
  contact3_email: string | null,                  
  contact3_phone: string | null,                  
  contact3_designation: string | null,             
  pan: string | null,                              
  gst: string | null,                              
  tan: string | null,                            
  state: string | null,                            
  cin: string | null,                              
  type: string | null,                             
  brands: string[]|null,                                
  logo: File | null,                               
  pan_file: File | null,                          
  gst_file: File | null,                           
  tan_file: File | null,                         
  cancelled_cheque: File | null,                  
  _successCallBack: (response: any) => void,       
  _errorCallBack: (errMessage: any) => void        
  ) => {
    const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
  const headers = {
    Authorization: `Bearer ${userDetailsFromStorage.token}`,
    'Content-Type': 'multipart/form-data',        
  };
  const formData = new FormData();
  formData.append('name', name!);
  formData.append('email', email!);
  formData.append('password', password!);
  formData.append('password_confirmation', password_confirmation!);
  formData.append('phone', phone!);
  formData.append('address', address!);
  formData.append('pincode', pincode!);
  formData.append('account_name', account_name!);
  formData.append('account_number', account_number!);
  formData.append('bank_name', bank_name!);
  formData.append('branch_name', branch_name!);
  formData.append('ifsc', ifsc!);
  formData.append('contact1_name', contact1_name!);
  formData.append('contact1_email', contact1_email!);
  formData.append('contact1_phone', contact1_phone!);
  formData.append('contact1_designation', contact1_designation!);
  if (contact2_name) formData.append('contact2_name', contact2_name);
  if (contact2_email) formData.append('contact2_email', contact2_email);
  if (contact2_phone) formData.append('contact2_phone', contact2_phone);
  if (contact2_designation) formData.append('contact2_designation', contact2_designation);
  if (contact3_name) formData.append('contact3_name', contact3_name);
  if (contact3_email) formData.append('contact3_email', contact3_email);
  if (contact3_phone) formData.append('contact3_phone', contact3_phone);
  if (contact3_designation) formData.append('contact3_designation', contact3_designation);
  formData.append('pan', pan!);
  formData.append('gst', gst!);
  formData.append('tan', tan!);
  formData.append('state', state!);
  formData.append('cin', cin!);
  formData.append('type', type!);
  if (brands && Array.isArray(brands)) {
    brands.forEach((brand, index) => {
      formData.append(`brands[${index}]`, brand);
    });
  } else {
    formData.append('brands', ''); 
  }
  if (logo) formData.append('logo', logo);
  if (pan_file) formData.append('pan_file', pan_file);
  if (gst_file) formData.append('gst_file', gst_file);
  if (tan_file) formData.append('tan_file', tan_file);
  if (cancelled_cheque) formData.append('cancelled_cheque', cancelled_cheque);
  axios
    .post(`${global.api_url}/api/companies`, formData, { headers })
    .then((response: AxiosResponse) => {
      if (response.status === 200 || response.status === 201) {
        _successCallBack(response.data); 
      }
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data); 
      }else  if (error.response?.status === 500) {
        _errorCallBack(error.response.data); 
      } else if (error.response?.status === 404) {
        _errorCallBack(error.response.data); 
      } else if (error.response?.status === 401) {
        localStorage.clear(); 
      } else {
        toast.error('Server Error'); 
      }
    });
};
import axios, { AxiosResponse, AxiosError } from "axios";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const params = new URLSearchParams(window.location.search);
const token = params.get('token')
export const getDocuments = (
    vehicles_id:any,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${global.api_url}/api/documents`, {
        params: {vehicles_id},
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
        else if(response.status === 201) {
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else if( error.response?.status === 404)
          {
            _errorCallBack(error.response.data);
          }
        else{
          toast.error("Server Error");
        }
      });
  };
  export const addDocuments = (
    drivers_license: File | null,
    aadhar: File | null,
    reg_cert: File | null,
    insurance: File | null,
    fitness_cert: File | null,
    pollution_cert: File | null,
    vehicles_id: any,
    user_id : any,
    
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void
  ) => {
    const formData = new FormData();
    if (drivers_license) formData.append('drivers_license', drivers_license);
    if (aadhar) formData.append('aadhar', aadhar);
    if (reg_cert) formData.append('reg_cert', reg_cert);
    if (insurance) formData.append('insurance', insurance);
    if (fitness_cert) formData.append('fitness_cert', fitness_cert);
    if (pollution_cert) formData.append('pollution_cert', pollution_cert);
    formData.append('vehicles_id', vehicles_id);
    formData.append('user_id', user_id);

    axios.post(`${global.api_url}/api/documents`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: AxiosResponse) => {
      if(response.status === 200) {
      _successCallBack(response.data);
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
       })
    .catch((error: AxiosError) => {
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }  else if( error.response?.status === 404)
        {
          _errorCallBack(error.response.data);
        }else {
        
        _errorCallBack(error);
      }
    });
  };
  export const deleteDocument = (
    userDocumentId:number | null,
    vehicleDocumentId: number | null,
    vehicleId: number | null,
    userId:number | null,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    const data = {
      user_documents_id:userDocumentId,
      veh_documents_id: vehicleDocumentId,
      vehicles_id: vehicleId,
      users_id:userId,
    };
    axios
      .delete(`${global.api_url}/api/documents`, {
        headers: headers,
        data: data 
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
        else if(response.status === 201) {
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        } 
        else if (error.response?.status === 404) {
          _errorCallBack(error.response.data);
        }
        else if (error.response?.status === 401) {
          localStorage.clear();
          toast.error("Login Expired.");
        } else {
          toast.error("Server Error");
        }
      });
  };
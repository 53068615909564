import React,{useState, useEffect} from 'react';
import { Grid, IconButton, Typography, Box, CssBaseline, Paper, Checkbox, FormControlLabel } from '@mui/material';
import { Doughnut, Pie, Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Chart } from 'chart.js';
import NavBar from '../../Components/Navbar/sidenav.tsx';
import Header from '../../Components/Header/header.tsx';
import Heading from '../../Components/Heading/heading.tsx';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import { getClients,getTrips,getVehicle,getFleetCount,getAverageKmPerVehicle, getTripStatus} from '../Dashboard/action.ts';
import { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement,ChartDataLabels);

const AverageKmPerVehicleToday = {
  labels: ['0','50','100','150'],
  datasets: [{
    data: ['50','50','50'],
    backgroundColor: ['#e74c3c', '#f4d73f', '#52be80'],
    hoverBackgroundColor: ['#52ba78', '#f4d89f', '#e7ad45'],
  }],
};
const AverageKmPerVehicleMonth = {
  labels: ['0-50 Km', '50-100 Km', '100-150 Km'],
  datasets: [{
    data: [50, 50, 50],
    backgroundColor: ['#e74c3c', '#f4d73f', '#52be80'],
    hoverBackgroundColor: ['#52ba78', '#f4d89f', '#e7ad45'],
  }],
};

const doughnutOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      onClick: null,
    },
    datalabels:{
      font:{
        size:18,
        weight:'bold'
      }
    }
  },
};
const pieOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
     display: false
    },
    datalabels:{
      font:{
        size:18,
        weight:'bold'
      }
    }
  },
};
const halfDoughnutOptions = {
  maintainAspectRatio: false,
  responsive: true,
  cutout: '50%',
  rotation: 270,
  circumference: 180,
  plugins: {
    legend: { display: false },
    tooltip: { enabled: true },
    datalabels: { display: false },
  },
};

const DashboardPage = () => {
  const [clientOptions, setClientOptions] = useState<{ id: string, label: string }[]>([]);
  const [date, setDate] = useState<string>('today');
  const [clientCount, setClientCount] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState<{ id: string, name: string } | null>(null);
  const [showEVStatus, setShowEVStatus] = useState(false);
  const [showICEStatus, setShowICEStatus] = useState(false);
  const [fleetCountDate, setFleetCountDate] = useState<string>('today');
  const [nonEvVehicleCount, setNonEvVehicleCount] = useState<string>('');
  const [evVehicleCount, setEvVehicleCount] = useState<string>('');
  const [lowestDistanceToday, setLowestDistanceToday] = useState<string>('');
  const [HighestDistanceToday, setHighestDistanceToday] = useState<string>('');
  const [averageDistanceToday, setAverageDistanceToday] = useState<any>('');
  const [dailyVehicleCounts, setDailyVehicleCounts] = useState<{ date: string, count: number }[]>([]);
  const [tripTimingData, setTripTimingData] = useState<number[]>([0, 0, 0, 0, 0, 0]);
  const monthNames: { [key: number]: string } = {1: 'January',2: 'February',3: 'March',4: 'April',5: 'May',6: 'June',7: 'July',8: 'August',9: 'September',10: 'October',11: 'November',12: 'December'};
  const [fleetPerMonth, setFleetPerMonth] = useState({labels: [ 'January', 'February', 'March', 'April', 'May', 'June',  'July', 'August', 'September', 'October', 'November', 'December'],datasets: [{ label: 'Vehicle Count per Month', data: Array(12).fill(0),backgroundColor: '#ab47bc',}],});
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem("userdetails");

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
    const savedClient = localStorage.getItem('lastSelectedClient');
    if (savedClient) {
      setSelectedClient(JSON.parse(savedClient));
    }
    if (isLoggedIn){
    fetchClients();}
  }, [isLoggedIn]);
  useEffect(() => {
      fetchAverageKmPerVehicle();
  },[date,selectedClient])
  useEffect(() => {
    fetchTripStatus();
  },[showEVStatus, selectedClient, showICEStatus])
  useEffect(() => {
    fetchTrips();
    fetchVehicle();
    fetchFleetCount();
  },[selectedClient])
  const fetchVehicle = () => {
    getVehicle(
      100,
      0,
      (response: any) => {
        if (response.status === 200) {
          const vehicleList = response.message.vehicles_list;
          const evCount = vehicleList.filter((vehicle: any) => vehicle.fuel_type === 'EV').length;
          const nonEvCount = vehicleList.filter((vehicle: any) => vehicle.fuel_type !== 'EV').length;
          setEvVehicleCount(evCount)
          setNonEvVehicleCount(nonEvCount);
        }
      },
      (errMessage: any) => {
        toast.error(errMessage);
        setEvVehicleCount('0');
        setNonEvVehicleCount('0');
      },
      selectedClient?.name || '',
    );
  };
  const fetchClients = () => {
    getClients(
      (response: any) => {
        const options = response.message.clients.map((clients: any) => ({
          id: clients.id,
          label: clients.name,
        }));
        setClientOptions(options);
        setClientCount(response.message.clients.length);
      },
      (errMessage: any) => {
        toast.error(errMessage);
      }
    );
  };
  const fetchAverageKmPerVehicle = () => {
    if (date === 'today') {
      const today = new Date();
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + 1);
      const formattedToday = today.toISOString().split('T')[0];
      const formattedNextDay = nextDay.toISOString().split('T')[0];
      getAverageKmPerVehicle(
        formattedToday,
        formattedNextDay,
        selectedClient?.id || '',
        (response: any) => {
          if (response.status === 200) {
            setLowestDistanceToday(response.data.lowest_km != null ? parseFloat(response.data.lowest_km).toFixed(2) : "0.00 Km");
            setHighestDistanceToday(response.data.highest_km != null ? parseFloat(response.data.highest_km).toFixed(2) : '0.00 Km');
            setAverageDistanceToday(response.data.average_distance != null ? parseFloat(response.data.average_distance).toFixed(2) : '0.00 Km');
          }
        },
        (errMessage: any) => {
          toast.error(errMessage);
        }
      );
    } else if (date === 'this_month') {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const formattedFirstDayOfMonth = firstDayOfMonth.toISOString().split('T')[0];
      const formattedToday = today.toISOString().split('T')[0];
      getAverageKmPerVehicle(
        formattedFirstDayOfMonth,
        formattedToday,
        selectedClient?.id || '',
        (response: any) => {
          if (response.status === 200) {
            setLowestDistanceToday(response.data.lowest_km != null ? parseFloat(response.data.lowest_km).toFixed(2) : '0.00 Km');
            setHighestDistanceToday(response.data.highest_km != null ? parseFloat(response.data.highest_km).toFixed(2) : '0.00 Km');
            setAverageDistanceToday(response.data.average_distance != null ? parseFloat(response.data.average_distance).toFixed(2) : '0.00Km');
          }
        },
        (errMessage: any) => {
          toast.error(errMessage);
        }
      );
    }
  };
  const fetchTripStatus = () => {
    let fuelType = '';
    if (showEVStatus) {
      fuelType = 'EV';
    } else if (showICEStatus) {
      fuelType = 'ICE';
    }
    const getOrdinal = (n: number) => {
      const s = ["th", "st", "nd", "rd"],
      v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    };
      getTripStatus(
        selectedClient?.id || '',
        fuelType,
        (response: any) => {
          if (response.status === 200) {
            const tripCounts: { [key: string]: { started: number, completed: number } } = {};
            let noTripCount = 0;
            response.data.forEach((vehicle: any) => {
              const tripEnd = vehicle.status.match(/Trip (\d+) ended/);
              const tripProgress = vehicle.status.match(/Trip (\d+) started/);
              if (tripEnd) {
                const tripNumber = tripEnd[1];
                if (!tripCounts[tripNumber]) {
                  tripCounts[tripNumber] = { started: 0, completed: 0 };
                }
                tripCounts[tripNumber].completed += 1;
              } else if (tripProgress) {
                const tripNumber = tripProgress[1];
                if (!tripCounts[tripNumber]) {
                  tripCounts[tripNumber] = { started: 0, completed: 0 };
                }
                tripCounts[tripNumber].started += 1;
              } else {
                noTripCount += 1;
              }
            });
            const labels: string[] = [];
            const data: number[] = [];
            const backgroundColor: string[] = [];
            const hoverBackgroundColor: string[] = [];
            if (noTripCount > 0) {
              labels.push("No trips started");
              data.push(noTripCount);
              backgroundColor.push('#FF0000');
              hoverBackgroundColor.push('#FF0000');
            }
            Object.keys(tripCounts).forEach(tripNumber => {
              const tripData = tripCounts[tripNumber];
              const ordinalTripNumber = getOrdinal(Number(tripNumber));
              const shadesOfGreenStarted = ['#A8E6A1', '#81C784', '#66BB6A', '#43A047', '#2E7D32', '#0B6B2C'];          
              const shadesOfGreenCompleted = ['#6DBB75', '#4CAF50', '#388E3C', '#2A7B2E', '#1B5E20', '#0A4A20'];          
              const tripNum = parseInt(tripNumber);           
              if (tripNum >= 1 && tripNum <= 5) {
                const index = tripNum - 1;
                if (tripData.started > 0) {
                  labels.push(`${ordinalTripNumber} trip in progress`);
                  data.push(tripData.started);
                  backgroundColor.push(shadesOfGreenStarted[index]);
                  hoverBackgroundColor.push(shadesOfGreenStarted[index]);
                }          
                if (tripData.completed > 0) {
                  labels.push(`${ordinalTripNumber} trip completed`);
                  data.push(tripData.completed);
                  backgroundColor.push(shadesOfGreenCompleted[index]);
                  hoverBackgroundColor.push(shadesOfGreenCompleted[index]);
                }
              } else if (tripNum > 5) {
                const progressLabel = "5+ trips in progress";
                const completedLabel = "5+ trips completed";
                            if (tripData.started > 0) {
                  const progressIndex = labels.findIndex(label => label === progressLabel);
                  if (progressIndex >= 0) {
                    data[progressIndex] += tripData.started;
                  } else {
                    labels.push(progressLabel);
                    data.push(tripData.started);
                    backgroundColor.push(shadesOfGreenStarted[5]);
                    hoverBackgroundColor.push(shadesOfGreenStarted[5]);
                  }
                }            
                if (tripData.completed > 0) {
                  const completedIndex = labels.findIndex(label => label === completedLabel);
                  if (completedIndex >= 0) {
                    data[completedIndex] += tripData.completed;
                  } else {
                    labels.push(completedLabel);
                    data.push(tripData.completed);
                    backgroundColor.push(shadesOfGreenCompleted[5]);
                    hoverBackgroundColor.push(shadesOfGreenCompleted[5]);
                  }
                }
              }
            });
            setPieData({
              labels,
              datasets: [
                {
                  data,
                  backgroundColor,
                  hoverBackgroundColor,
                },
              ],
            });
          }
        },
        (errMessage: any) => {
          setPieData({
            labels: ["No Data"],
            datasets: [
              {
                data: [],
                backgroundColor: ['#d3d3d3'],
                hoverBackgroundColor: ['#d3d3d3'],
              },
            ],
          });
          toast.error(errMessage.message);
        }
      );
  };
  const fetchTrips =() => {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];
    getTrips(
      50,
      0,
      formattedToday,
      formattedToday,
      (response: any) => {
        if (response.status === 400) {
          setTripTimingData('')
        } else {
          const tripsList = response.message.trips_list;
          processTripData(tripsList);  
        }
      },
      (errMessage: any) => {
        toast.error(errMessage.message);
      },
      '',
      selectedClient?.name || '',
    );
  }
  const processTripData = (tripsList: any[]) => {
    const timeRangeCounts = [0, 0, 0, 0, 0, 0];
    tripsList.forEach((trip: any) => {
      const startTime = dayjs(trip.start_time).hour();
      if (startTime >= 5 && startTime < 8) {
        timeRangeCounts[0]++;
      } else if (startTime >= 8 && startTime < 10) {
        timeRangeCounts[1]++;
      } else if (startTime >= 10 && startTime < 12) {
        timeRangeCounts[2]++;
      } else if (startTime >= 12 && startTime < 14) {
        timeRangeCounts[3]++;
      } else if (startTime >= 14 && startTime < 16) {
        timeRangeCounts[4]++;
      } else if (startTime >= 16 && startTime < 18) {
        timeRangeCounts[5]++;
      }
    });
    setTripTimingData(timeRangeCounts);
  };
  const fetchFleetCount = () => {   
    const today = new Date();
    const sixDaysAgo = new Date(today);
    sixDaysAgo.setDate(today.getDate() - 6);
    const formattedToday = today.toISOString().split('T')[0];
    const formattedSixDaysAgo = sixDaysAgo.toISOString().split('T')[0];
    const thisYear = new Date(today.getFullYear(), 0, 1);
    const formattedThisYear = thisYear.toISOString().split('T')[0];
    getFleetCount(
      formattedThisYear,
      formattedToday,
      (response: any) => {
        if (response.status === 200) {
          const runningVehicles = response.message.running_vehicles_today;
          const clientNames = Object.keys(runningVehicles);
          const vehicleCountsClient = Object.values(runningVehicles) as number[];
          setDoughnutData({
            labels: clientNames,
            datasets: [
              {
                data: vehicleCountsClient,
                backgroundColor: clientNames.map((_, index) => {
                  const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
                  return colors[index % colors.length];
                }),
                hoverBackgroundColor: clientNames.map((_, index) => {
                  const hoverColors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
                  return hoverColors[index % hoverColors.length];
                }),
              },
            ],
          });
          const { vehicle_count_per_period } = response.message;
          const monthlyCounts = Object.entries(vehicle_count_per_period).flatMap(([client, periods]) =>
            periods.map((period) => ({
              client,
              month: period.month,
              year: period.year,
              count: period.vehicle_count,
            }))
          );
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1; 
          const monthLabels = Array.from({ length: currentMonth }, (_, i) => monthNames[i + 1]);
          const dataset = Array.from({ length: currentMonth }, (_, i) => {
            const monthNumber = i + 1;
            if (!selectedClient) {
              const countsForMonth = monthlyCounts
                .filter((data) => data.month === monthNumber && data.year === currentYear && 
                                 (data.client === "Dmart" || data.client === "Hmart" || data.client === "HmartChennai"))
                .reduce((acc, curr) => acc + curr.count, 0);
              return countsForMonth || 0;
            } else {
              const countsForMonth = monthlyCounts
                .filter((data) => data.client === selectedClient.name && data.month === monthNumber && data.year === currentYear)
                .reduce((acc, curr) => acc + curr.count, 0);
              return countsForMonth || 0;
            }
          });
          setFleetPerMonth({
            labels: monthLabels,
            datasets: [
              {
                label: 'Highest Vehicle Count per Month',
                data: dataset,
                backgroundColor: '#ab47bc',
              },
            ],
          });
        } else {
          toast.success(response.message);
        }
      },
      (errMessage: any) => {
        setDoughnutData({
          labels: ["No Data"],
          datasets: [
            {
              data: [1],
              backgroundColor: ['#d3d3d3'],
              hoverBackgroundColor: ['#d3d3d3'],
            },
          ],
        });
      }
    );
    if (fleetCountDate === 'today') {
      getFleetCount(
        formattedSixDaysAgo,
        formattedToday,
        (response) => {
          const { vehicle_count_per_period } = response.message;
          const formattedDailyCounts = Object.entries(vehicle_count_per_period).flatMap(([client, periods]) => 
            periods.map((period) => ({
              client,
              date: period.date,
              count: period.vehicle_count,
            }))
          );
          const dateRange = [];
          const today = new Date(formattedToday);
          for (let i = 6; i >= 0; i--) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            dateRange.push(date.toISOString().split('T')[0]);
          }
          const completeDailyCounts = dateRange.map(date => {
            if (!selectedClient) {
              const countForDate = formattedDailyCounts
                .filter(data => data.date === date )
                .reduce((sum, data) => sum + data.count, 0);
    
              return {
                date,
                count: countForDate
              };
            } else {
              const countData = formattedDailyCounts.find(data => data.date === date && data.client === selectedClient.name);
              return {
                date,
                count: countData ? countData.count : 0
              };
            }
          });
          setDailyVehicleCounts(completeDailyCounts);
        },
        (errMessage) => {
          toast.error(errMessage.message);
        }
      );
    }
  };
  const [doughnutData, setDoughnutData] = useState({
    labels: [] as string[],
    datasets: [
      {
        data: [] as number[],
        backgroundColor: [] as string[],
        hoverBackgroundColor: [] as string[],
      },
    ],
  });
  const [pieData, setPieData] = useState({
    labels: [] as string[],
    datasets: [
      {
        data: [] as number[],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  });
  const handleClientChange = (event: SelectChangeEvent<string>) => {
    const clientId = event.target.value;
    const client = clientOptions.find(c => c.id === clientId);
    const transformedClient = client ? { id: client.id, name: client.label } : null;
    setSelectedClient(transformedClient);
    localStorage.setItem('lastSelectedClient', JSON.stringify(transformedClient));
  }
  const handleDateChange = (event: SelectChangeEvent<string>) => {
    setDate(event.target.value);
  }
  const handleFleetCountDateChange = (event: SelectChangeEvent<string>) => {
    setFleetCountDate(event.target.value);
  }
  const dateSelection = [
    { id: 'today', label: 'Today' },
    { id: 'this_month', label: 'This Month' },
  ];
  const tripTiming = {
    labels: ['5-8', '8-10', '10-12', '12-2', '2-4', '4-6'],
    datasets: [{
      label: 'Number of Trips',
      data: tripTimingData,
      backgroundColor: '#42a5f5',
    }],
  };
  const fleetToday = {
    labels: dailyVehicleCounts.map(entry => entry.date),
    datasets: [
      {
        label: 'Vehicle Count per Day',
        data: dailyVehicleCounts.map(entry => entry.count),
        backgroundColor: '#18bdde',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };
  const barChartOptions = {
    plugins: {
      datalabels: {
        display: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: function(value) {
            if (Number.isInteger(value)) {
              return value;
            }
          },
        },
      },
    },
  };
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      <Box sx={{ width: '250px', position: 'fixed', height: '100vh' }}>
        <NavBar selected="Dashboard" />
      </Box>
      <Box>
        <Header />
        <Box sx={{ flexGrow: 1,marginTop:'70px',marginLeft:'170px'}}>
          <Heading title="Dashboard" closeIcon="false"  
            leftContent={
              <>
                <Dropdown
                  options={clientOptions}
                  selectedValue={selectedClient?.id || ''}
                  handleChange={(event) => handleClientChange(event)}
                  placeholder="Choose Client"
                  width='190px'
                  height='40px'
                  displayEmpty={true}
                />
                <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold', marginLeft:'30px' }}>
                  Total Clients: {clientCount}
                </Typography>
              </>
            } 
          />
        </Box>
        <Box sx={{ flexGrow: 1, marginLeft: '180px'}}>
          <Box sx={{ flexGrow: 1, padding: 2, overflow: 'auto' }}>
            <Grid container spacing={2} sx={{ height: '100%' }}>
              <Grid item xs={12} sm={4}>
                <Paper elevation={8} sx={{ padding: 2, height: '100%' }}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="h6" gutterBottom>
                        Average Km per vehicle
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Dropdown
                        options={dateSelection}
                        selectedValue={date}
                        handleChange={handleDateChange}
                        placeholder=""
                        width="120px"
                        height="30px"
                        displayEmpty={true}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ height: '70%', width: '90%' }}>
                    <Box sx={{ height: '80%', width: '80%', margin: '0 auto' }}>
                      <Doughnut data={date === 'today' ? AverageKmPerVehicleToday : AverageKmPerVehicleMonth} options={halfDoughnutOptions} />
                    </Box>
                    <Box sx={{ width: '100%', textAlign: 'center' }}>  {/* Left-align text */}
                      <Typography variant="body1" sx={{ color: 'black' }}>Lowest: {lowestDistanceToday} km</Typography>
                      <Typography variant="body1" sx={{ color: 'black' }}>Average: {averageDistanceToday} km</Typography>
                      <Typography variant="body1" sx={{ color: 'black' }}>Highest: {HighestDistanceToday} km</Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper elevation={8} sx={{ padding: 2, height: '100%' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                      Trip Status Today
                    </Typography>
                    <Box sx={{marginLeft:'50px'}}>
                      <FormControlLabel
                        control={
                          <Checkbox
                          checked={showEVStatus}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setShowEVStatus(true);
                              setShowICEStatus(false);
                            } else {
                              setShowEVStatus(false);
                            }
                          }}
                          color="primary"
                        />
                        }
                        label="EV"
                      />
                    </Box>
                    <Box sx={{marginLeft:'3px'}}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showICEStatus}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setShowICEStatus(true);
                                setShowEVStatus(false);
                              } else {
                                setShowICEStatus(false);
                              }
                            }}
                            color="primary"
                          />
                        }
                        label="ICE"
                      />
                    </Box>
                  </Box>
                  <Box sx={{ height: 200 }}>
                    <Pie data={pieData} options={doughnutOptions} />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper elevation={8} sx={{ padding: 2, height: '100%' }}>
                  <Typography variant="h6" gutterBottom align="center">
                    Fleet Count Today
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', height: 200, }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Doughnut data={doughnutData} options={pieOptions} />
                    </Box>
                    <Box
                      sx={{
                        width: '300px',
                        maxHeight: 200,
                        overflowY: 'auto',
                        padding: 1,
                        borderLeft: '1px solid #ddd', 
                        marginLeft:'-50px'  
                      }}
                    >
                      <ul style={{ padding: 0 }}>
                        {doughnutData?.labels?.map((clientName, index) => (
                          <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px',}}>
                            <span
                              style={{
                                display: 'inline-block',
                                width: '20px',
                                height: '20px',
                                marginRight: '8px',
                                backgroundColor: doughnutData.datasets[0].backgroundColor[index],
                              }}
                            />
                            {clientName}
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper elevation={8} sx={{ padding: 2, height: '100%' }}>
                  <Typography variant="h6" gutterBottom align="center">
                    Trip Timings Today
                  </Typography>
                  <Box sx={{ height: 200 }}>
                    <Bar data={tripTiming} options={barChartOptions} />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper elevation={8} sx={{ padding: 2, height: '100%' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: 4 }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
                        <Typography variant="h6" gutterBottom align="center">
                          ICE Count
                        </Typography>
                        <IconButton>
                          <LocalShippingIcon style={{ color: 'rgb(233 91 91)', scale: '2' }} />
                        </IconButton>
                        <Typography>
                          {nonEvVehicleCount}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
                        <Typography variant="h6" gutterBottom align="center">
                          EV Count
                        </Typography>
                        <IconButton>
                          <LocalShippingIcon style={{ color: '#52be80', scale: '2' }} />
                        </IconButton>
                        <Typography>
                          {evVehicleCount}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper elevation={8} sx={{ padding: 2, height: '100%' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                      Fleet Count
                    </Typography>
                    <Dropdown
                      options={dateSelection}
                      selectedValue={fleetCountDate}
                      handleChange={(event) => handleFleetCountDateChange(event)}
                      placeholder=""
                      width="120px"
                      height="30px"
                      displayEmpty={true}
                    />
                  </Box>
                  <Box sx={{ height: 200 }}>
                    <Bar data={fleetCountDate === 'today' ? fleetToday : fleetPerMonth} options={barChartOptions} />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default DashboardPage;

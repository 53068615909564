import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, Tabs, Tab, Box, TextField, Button, IconButton,Tooltip,SelectChangeEvent,Typography} from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import { validateCompanyName } from '../../Utils/NameValidation/name-validation.tsx';
import './editCompanyModal.scss';
import UploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { validateEmail } from '../../Utils/EmailValidation/email-validation.tsx';
import { validatePhoneNumber } from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import { validatePincode } from '../../Utils/PincodeValidation/pincode-validation.tsx';
import {editCompany} from './action.ts';
import ConfirmationPopup from '../../Components/ConfirmationModal/confirmation-modal.tsx';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import {validateBranchName} from '../../Utils/BranchNameValidation/branchname-validation.tsx';
import {validateAccountNumber} from '../../Utils/AccountNumberValidation/account-number-validation.tsx';
import {validateAddress} from '../../Utils/AddressValidation/address-validation.tsx';
import {validateIFSC} from '../../Utils/IFSCValidation/ifsc-validation.tsx';
import {validateCin} from '../../Utils/CinValidation/cin-validation.tsx';
import {validatePan} from '../../Utils/PANValidation/pan-validation.tsx';
import {validateTan} from '../../Utils/TANValidation/tan-validation.tsx';
import {validateGST} from '../../Utils/GSTValidation/gst-validation.tsx';
import { validateDriverName } from '../../Utils/NameValidation/name-validation.tsx';
import '../../Components/Tab/tab-style.scss';
import '../../Components/TextField/text-field-style.scss';

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

interface Data {
  company_name: string;
  type : string,
  state : string,
  cin : string,
  pan_number : string,
  tan_number : string,
  gst_number : string,
  email: string;
  password : string;
  mobile_number: string;
  address: string;
  pincode: string;
  bank_details: string;
  branch_name: string;
  account_name: string;
  account_number: string;
  ifsc: string;
  pan: string;
  gst: string;
  tan: string;
  logo :string;
  cancelled_cheque: string;
  contact1_name :string,
  contact1_email : string,
  contact1_phone : string,
  contact1_designation : string,
  contact2_name :string,
  contact2_email : string,
  contact2_phone : string,
  contact2_designation : string,
  contact3_name :string,
  contact3_email : string,
  contact3_phone : string,
  contact3_designation : string,
  vendor_name: string,
}

interface EditCompanyModalProps {
  open: boolean;
  onClose: () => void;
  selectedData: Data;
  selectedId: number;
}

const EditCompanyModal: React.FC<EditCompanyModalProps> = ({ open, onClose, selectedData, selectedId }) => {
  
  const [activeTab, setActiveTab] = useState(0);
  const [cinError, setCinError] = useState<string>('');
  const [typeToDelete, setTypeToDelete] = useState<string>('');
  const [stateError, setStateError] = useState<string>('');
  const [companyNameError, setCompanyNameError] = useState<string>('');
  const [contactNameOneError, setContactNameOneError] = useState<string>('');
  const [contactNameTwoError, setContactNameTwoError] = useState<string>('');
  const [contactNameThreeError, setContactNameThreeError] = useState<string>('');
  const [contactEmailOneError, setContactEmailOneError] = useState<string>('');
  const [contactEmailTwoError, setContactEmailTwoError] = useState<string>('');
  const [contactEmailThreeError, setContactEmailThreeError] = useState<string>('');
  const [contactNumberOneError, setContactNumberOneError] = useState<string>('');
  const [contactNumberTwoError, setContactNumberTwoError] = useState<string>('');
  const [contactNumberThreeError, setContactNumberThreeError] = useState<string>('');
  const [contactDesignationOneError, setContactDesignationOneError] = useState<string>('');
  const [contactDesignationTwoError, setContactDesignationTwoError] = useState<string>('');
  const [contactDesignationThreeError, setContactDesignationThreeError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [pincodeError, setPincodeError] = useState<string>('');
  const [mobileNumberError, setMobileNumberError] = useState<string>('');
  const [addressError, setAddressError] = useState<string>('');
  const [bankNameError, setBankNameError] = useState<string>('');
  const [branchNameError, setBranchNameError] = useState<string>('');
  const [accountNameError, setAccountNameError] = useState<string>('');
  const [accountNumberError, setAccountNumberError] = useState<string>('');
  const [ifscError, setIfscError] = useState<string>('');
  const [panError, setPanError] = useState<string>('');
  const [tanError, setTanError] = useState<string>('');
  const [gstError, setGstError] = useState<string>('');
  const [documentList, setDocumentList] = useState<any[]>([]);
  const [accountType, setAccountType] =  useState('');
  const [tabError, setTabError] = useState<number | null>(null);
  const [icons, setIcons] = useState<{
    [key: string]: { view: boolean; close: boolean; upload: boolean };
    }>({
      pan: { view: false, close: false, upload: true },
      tan: { view: false, close: false, upload: true },
      gst: { view: false, close: false, upload: true },
      logo: { view: false, close: false, upload: true },
      cancelledCheque: { view: false, close: false, upload: true },
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: File | null }>({
      pan: null,
      tan: null,
      gst: null,
      logo : null,
      cancelledCheque: null,
  });
  const typesOptions = [
      { id: 'vendor', label: 'Vendor'},
      { id: 'client', label: 'Client' },
  ];
  const [formValues, setFormValues] = useState({
      companyName: '',
      type : '',
      state : '',
      cin : '',
      pan_number : '',
      tan_number :'',
      gst_number :'',
      email: '',
      password : '',
      mobile_number: '',
      address: '',
      pincode: '',
      bank_details: '',
      branch_name: '',
      account_name: '',
      account_number: '',
      ifsc: '',
      contactNameOne :'',
      contactEmailOne : '',
      contactNumberOne : '',
      contactDesignationOne : '',
      contactNameTwo :'',
      contactEmailTwo : '',
      contactNumberTwo : '',
      contactDesignationTwo : '',
      contactNameThree :'',
      contactEmailThree : '',
      contactNumberThree : '',
      contactDesignationThree : '',
  });
  useEffect(() => {
    resetState();
    if (selectedData) {
      const vendorNames = selectedData.vendor_name ? selectedData.vendor_name.split(',').map(name => name.trim()) : [];
      setFormValues({
        companyName: selectedData.company_name || '',
        type : selectedData.type||'',
        state : selectedData.state||'',
        cin : selectedData.cin||'',
        pan_number:  selectedData.pan_number || '',
        tan_number:  selectedData.tan_number || '',
        gst_number:  selectedData.gst_number || '',
        email: selectedData.email || '',
        password : selectedData.password ||'',
        mobile_number: selectedData.mobile_number || '',
        address: selectedData.address || '',
        pincode: selectedData.pincode || '',
        bank_details: selectedData.bank_details || '',
        branch_name: selectedData.branch_name || '',
        account_name: selectedData.account_name || '',
        account_number: selectedData.account_number || '',
        ifsc: selectedData.ifsc || '',
        contactNameOne: selectedData.contact1_name||'',
        contactEmailOne: selectedData.contact1_email||'',
        contactNumberOne : selectedData.contact1_phone||'',
        contactDesignationOne :selectedData.contact1_designation||'',
        contactNameTwo: selectedData.contact2_name||'',
        contactEmailTwo: selectedData.contact2_email||'',
        contactNumberTwo: selectedData.contact2_phone||'',
        contactDesignationTwo: selectedData.contact2_designation||'',
        contactNameThree: selectedData.contact3_name||'',
        contactEmailThree: selectedData.contact3_email||'',
        contactNumberThree: selectedData.contact3_phone||'',
        contactDesignationThree :selectedData.contact3_designation||'',
  });
      const loadedDocuments = [
        { document_name: 'pan', location: selectedData.pan, status: selectedData.pan ? 'uploaded' : 'not uploaded' },
        { document_name: 'gst', location: selectedData.gst, status: selectedData.gst ? 'uploaded' : 'not uploaded' },
        { document_name: 'tan', location: selectedData.tan, status: selectedData.tan ? 'uploaded' : 'not uploaded' },
        { document_name: 'logo', location: selectedData.logo, status: selectedData.logo ? 'uploaded' : 'not uploaded' },
        { document_name: 'cancelledCheque', location: selectedData.cancelled_cheque, status: selectedData.cancelled_cheque ? 'uploaded' : 'not uploaded' },
      ];
      setDocumentList(loadedDocuments);
      const updatedIcons = { ...icons };
      loadedDocuments.forEach((doc) => {
        if (doc.status === 'uploaded') {
          updatedIcons[doc.document_name] = { view: true, close: true, upload: false };
        } else {
          updatedIcons[doc.document_name] = { view: false, close: false, upload: true };
        }
      });
      setIcons(updatedIcons);
    }
  
  }, [selectedData]);
  const accountTypesOptions = [
    { id: 'current', label: 'Current'},
    { id: 'recurring', label: 'Recurring'},
    { id: 'savings', label: 'Savings' },
  ];
  const resetState = () => {
    setCinError('');
    setTypeToDelete('');
    setStateError('');
    setCompanyNameError('');
    setContactNameOneError('');
    setContactNameTwoError('');
    setContactNameThreeError('');
    setContactEmailOneError('');
    setContactEmailTwoError('');
    setContactEmailThreeError('');
    setContactNumberOneError('');
    setContactNumberTwoError('');
    setContactNumberThreeError('');
    setContactDesignationOneError('');
    setContactDesignationTwoError('');
    setContactDesignationThreeError('');
    setEmailError('');
    setPincodeError('');
    setMobileNumberError('');
    setAddressError('');
    setBankNameError('');
    setBranchNameError('');
    setAccountNameError('');
    setAccountNumberError('');
    setIfscError('');
    setPanError('');
    setTanError('');
    setGstError('');
    setActiveTab(0); 
  };
  const maxFileSize = 1 * 1024 * 1024; 
  const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
  const handleTabChange = (newTabIndex) => {
    const tabErrors = [
        companyNameError || mobileNumberError || emailError || addressError || pincodeError || stateError || cinError,
        contactNameOneError || contactNameTwoError || contactNameThreeError || contactEmailOneError || contactEmailTwoError || contactEmailThreeError || contactDesignationOneError || contactDesignationTwoError || contactDesignationThreeError || contactNumberOneError || contactNumberTwoError || contactNumberThreeError,
        accountNameError || accountNumberError || bankNameError || branchNameError || ifscError
    ];
    const tabNames = ['Company Details', 'Additional Details', 'Bank Details', 'Documents'];

    if (newTabIndex > activeTab) {
      for (let i = 0; i < newTabIndex; i++) {
        if (tabErrors[i]) {
          toast.error(`Please fix the errors in Tab ${tabNames[i]}`);
          setTabError(i);
          return;
        }
      }
    }
    setTabError(null);
    setActiveTab(newTabIndex);
};
 const handleNameChange = (field, errorSetter) => (event) => {
   const { value } = event.target;
   if (value === '') {
      errorSetter(''); 
   } else if (validateCompanyName(value)) {
      errorSetter(''); 
   } else {
      errorSetter('Invalid character.');
   }
   setFormValues((prev) => ({
    ...prev,
    [field]: value, 
   }));
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      switch (name) {
          case 'email':
              setEmailError('');
              break;
          case 'contactEmailOne':
              setContactEmailOneError('');
              break;
          case 'contactEmailTwo':
              setContactEmailTwoError('');
              break;
          case 'contactEmailThree':
              setContactEmailThreeError('');
              break;
          default:
              break;
      }
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFormValues((prev) => ({
      ...prev,
      password: value,
    }));
  };
  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
     const { value } = event.target;
     setFormValues(prev => ({
        ...prev,
        state: value.toUpperCase(),
     }));
    if (value === '') {
      setStateError('');
    } else if (validateDriverName(value.toUpperCase())) {
      setStateError('');
    } else {
      setStateError('Only alphabets are allowed');
    }
  };
  const handleEmailBlur = (emailField, setError) => {
    const emailValue = formValues[emailField];
  
    if (emailValue === '') {
      setError('');
    } else if (validateEmail(emailValue)) {
      setError('');
    } else {
      setError('Please enter a valid email address.');
    }
  };
  const handlePhoneNumberBlur = () => {
    if (formValues.mobile_number === '') {
      setMobileNumberError('');
    } else if(mobileNumberError){
      setMobileNumberError(mobileNumberError);
    }
    else if (formValues.mobile_number.length >= 1 && formValues.mobile_number.length < 10) {
      setMobileNumberError('Please enter a 10 digit number');
    }
  };
 
  const handleAddressChange = (event) => {
    const { value } = event.target;
    setFormValues(prev => ({
      ...prev,
      address: value,
    }));
    if (value === '') {
      setAddressError('');
    } else if (validateAddress(value)) {
      setAddressError('');
    } else {
      setAddressError('Invalid characters');
    }
  };  
  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '') {
      setMobileNumberError("");
    } else {
      const { errorMessage } = validatePhoneNumber(value);
      setMobileNumberError(errorMessage);
    }
    setFormValues((prev) => ({
      ...prev,
      mobile_number: value,
    }));
  };
  const handleContactNumberOneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '') {
      setContactNumberOneError("");
    } else {
      const { errorMessage } = validatePhoneNumber(value);
      setContactNumberOneError(errorMessage);
    }
    setFormValues((prev) => ({
      ...prev,
      contactNumberOne: value,
    }));
  };
  const handleContactNumberTwoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '') {
      setContactNumberTwoError("");
    } else {
      const { errorMessage } = validatePhoneNumber(value);
      setContactNumberTwoError(errorMessage);
    }
    setFormValues((prev) => ({
      ...prev,
      contactNumberTwo: value,
    }));
  };
  const handleContactNumberThreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '') {
      setContactNumberThreeError("");
    } else {
      const { errorMessage } = validatePhoneNumber(value);
      setContactNumberThreeError(errorMessage);
    }
    setFormValues((prev) => ({
      ...prev,
      contactNumberThree: value,
    }));
  };
  const handleContactNumberOneBlur = () => {
    if (formValues.contactNumberOne === '') {
      setContactNumberOneError('');
    } else if(contactNumberOneError){
      setContactNumberOneError(contactNumberOneError);
    }
    else if (formValues.contactNumberOne.length >= 1 && formValues.contactNumberOne.length < 10) {
      setContactNumberOneError('Please enter a 10 digit number');
    }
  };
  const handleContactNumberTwoBlur = () => {
    if (formValues.contactNumberTwo === '') {
      setContactNumberOneError('');
    } else if(contactNumberTwoError){
      setContactNumberTwoError(contactNumberTwoError);
    }
    else if (formValues.contactNumberTwo.length >= 1 && formValues.contactNumberTwo.length < 10) {
      setContactNumberTwoError('Please enter a 10 digit number');
    }
  };
  const handleContactNumberThreeBlur = () => {
    if (formValues.contactNumberThree === '') {
      setContactNumberThreeError('');
    } else if(contactNumberThreeError){
      setContactNumberThreeError(contactNumberThreeError);
    }
    else if (formValues.contactNumberThree.length >= 1 && formValues.contactNumberThree.length < 10) {
      setContactNumberThreeError('Please enter a 10 digit number');
    }
  };
  const handleBankNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '') {
      setBankNameError('');
    } else if (validateCompanyName(value)) {
      setBankNameError('');
    } else {
      setBankNameError('Invalid Input.Only characters are allowed');
    }
    setFormValues((prev) => ({
      ...prev,
      bank_details: value,
    }));
  };
  const handleBranchNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
     if (value === '') {
         setBranchNameError('');
     } else if (validateBranchName(value)) {
          setBranchNameError('');
    } else {
          setBranchNameError("Invalid Input.Only characters are allowed");
    }
    setFormValues(prev => ({
          ...prev,
          branch_name: value,
    }));
   };
  const handleIfscChange = (event) => {
    const { value } = event.target;
    const ifsc = value.toUpperCase();
    setFormValues(prev => ({
        ...prev,
        ifsc: ifsc,
    }));
    if (ifsc === '') {
      setIfscError('');
      return; 
    } else  {
      const newError = validateIFSC(ifsc);
      setIfscError(newError);
    }
  };
  const handlePincodeBlur = () => {
    if (formValues.pincode === '') {
      setPincodeError(" ");
    }
    if (formValues.pincode.length >= 1 && formValues.pincode.length < 6) {
      setPincodeError("Pincode must be 6 digits");
    }
  };
  const handleIfscBlur = () => {
    if (formValues.ifsc === '') {
     setIfscError('');
    } else if(ifscError){
      setIfscError('');
    }
    else if (formValues.ifsc.length>=1&&formValues.ifsc.length<11) {
      setIfscError("IFSC must be 11 digits");
    } 
  };  
  const handleCinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const cin = value.toUpperCase();
    setFormValues(prev => ({
        ...prev,
        cin: cin,
    }));
    if (cin === '') {
    setCinError('')
    return; 
  
    } else  {
      const newError = validateCin(cin);
      setCinError(newError);
    }
  };
  const handleCinBlur = () => {
    if (formValues.cin === '') {
      setCinError('');
    } else if(cinError){
      setCinError(cinError);
    }
    else if (formValues.cin.length>=1&&formValues.cin.length<21) {
      setCinError('CIN must be a 21 digit number');
    } 
  };
  const handlePincodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFormValues((prev) => ({
      ...prev,
      pincode: value,
    }));
    const error = validatePincode(value);
    setPincodeError(error);
  };
  const handleAccountTypeChange = (event) => {
    setAccountType(event.target.value);
    setFormValues(prev => ({
      ...prev,
      account_name: event.target.value,
    }));
  };
  const handleBankNameBlur = () => {
    if (bankNameError === '') {
      setBankNameError('');
    } else {
      setBankNameError(bankNameError);
    }
  };
  const handleAccountNameBlur = () => {
    if (accountNameError === '') {
      setAccountNameError('');
    } else {
      setAccountNameError(accountNameError);
    }
  };
  const handleAccountNumberBlur = () => {
    if (formValues.account_number === '') {
     setAccountNumberError('');
    } else if(accountNumberError){
      setAccountNumberError(accountNumberError);
    }
    else if (formValues.account_number.length>=1&&formValues.account_number.length<9) {
      setAccountNumberError('Please Enter atleast 9 characters');
    } 
  };
  const handleAccountNumberChange = (event) => {
    const { value } = event.target;
    setFormValues(prev => ({
      ...prev,
      account_number: value,
    }));
    if (value === '') {
       setAccountNumberError('');
    } else  {
       const  errorMessage  = validateAccountNumber(value);
       setAccountNumberError(errorMessage);
    }
  };
  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    setFormValues((prev) => ({
      ...prev,
      type: event.target.value,
    }));
  };
  const handlePanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const pan = value.toUpperCase();
    setFormValues(prev => ({
        ...prev,
        pan_number: pan,
    }));
    if (pan === '') {
       setPanError('')
       return; 
    } else  {
      const newError = validatePan(pan);
      setPanError(newError);
    }
  };
  const handleTanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const tan = value.toUpperCase();
    setFormValues(prev => ({
        ...prev,
        tan_number: tan,
    }));
    if (tan === '') {
      setTanError('')
      return; 
    } else  {
      const newError = validateTan(tan);
      setTanError(newError);
    }
  };
  const handleGSTChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const gst = value.toUpperCase();
    setFormValues(prev => ({
        ...prev,
        gst_number: gst,
    }));
    if (gst === '') {
       setGstError('');
       return; 
    } else  {
          const newError = validateGST(gst,formValues.pan_number);
          setGstError(newError);
          return ;
    }
  };
  const handlePanBlur = () => {
    if (formValues.pan_number === '') {
      setPanError('');
    } else if(panError){
      setPanError(panError);
    }
    else if (formValues.pan_number.length>=1&&formValues.pan_number.length<10) {
      setPanError("Pan must be 10 digits");
    } 
    else if (formValues.pan_number!=selectedData.pan_number){
      toast.error("Please change the GST also");
    }
  };
  const handleTanBlur = () => {
    if (formValues.tan_number === '') {
      setTanError('');
    } else if(tanError){
      setTanError(tanError);
    }
    else if (formValues.tan_number.length>=1&&formValues.tan_number.length<10) {
      setTanError("Tan must be 10 digits");
    } 
  };
  const handleGstBlur = () => {
    if (formValues.gst_number === '') {
      setGstError('');
    } else if(gstError){
      setGstError(gstError);
    }
    else if (formValues.gst_number.length>=1&&formValues.gst_number.length<15) {
      setGstError('GST must be a 15 digit number');
    } 
  };
  const handleClickCancel = () => {
    onClose();
    resetState();
  };
  const handleClickSave = () => {
  
   const save = formValues.companyName!=selectedData.company_name||formValues.mobile_number!=selectedData.mobile_number||formValues.email!=selectedData.email||formValues.address!=selectedData.address||
                 formValues.pincode!=selectedData.pincode||formValues.state!=selectedData.state||formValues.cin!=selectedData.cin||formValues.account_name!=selectedData.account_name||formValues.account_number!=selectedData.account_number
                 ||formValues.ifsc!=selectedData.ifsc||formValues.bank_details!=formValues.bank_details||formValues.pan_number!=selectedData.pan_number||formValues.tan_number!=selectedData.tan_number||formValues.gst_number!=selectedData.gst_number||
                 formValues.contactNameOne!=selectedData.contact1_name||formValues.contactNameTwo!=selectedData.contact2_name||formValues.contactNameThree!=selectedData.contact3_name||formValues.contactNumberOne!=selectedData.contact1_phone||formValues.contactNumberTwo!=selectedData.contact2_phone||
                 formValues.contactNumberThree!=selectedData.contact3_phone||formValues.contactEmailOne!=selectedData.contact1_email||formValues.contactEmailTwo!=selectedData.contact2_email||formValues.contactEmailThree!=selectedData.contact3_email||formValues.contactDesignationOne!=selectedData.contact1_designation||
                 formValues.contactDesignationTwo!=selectedData.contact2_designation||formValues.contactDesignationThree!=selectedData.contact3_designation||selectedFiles.pan||selectedFiles.tan||selectedFiles.gst||selectedFiles.logo||selectedFiles.cancelled_cheque||formValues.type!=selectedData.type;
   if(panError||tanError||gstError){
      toast.error("Please fix the errors");
      return;
   }
   if (selectedData.pan_number!=formValues.pan_number&&selectedData.gst_number===formValues.gst_number)
   {
    toast.error("Please update the GST if you have updated the PAN ");
    return;
   }
   else if(!panError&&!tanError&&!gstError&&save){
     editCompany
      (
        selectedId,
        formValues.companyName,
        formValues.email,
        formValues.password,
        formValues.mobile_number,
        formValues.address,
        formValues.pincode,
        formValues.account_name,
        formValues.account_number,
        formValues.bank_details,
        formValues.branch_name,
        formValues.ifsc,
        formValues.contactNameOne,
        formValues.contactEmailOne,
        formValues.contactNumberOne,
        formValues.contactDesignationOne,
        formValues.contactNameTwo,
        formValues.contactEmailTwo,
        formValues.contactNumberTwo,
        formValues.contactDesignationThree,
        formValues.contactNameThree,
        formValues.contactEmailThree,
        formValues.contactNumberThree,
        formValues.contactDesignationThree,
        formValues.pan_number,
        formValues.gst_number,
        formValues.tan_number,
        formValues.state,
        formValues.cin,
        formValues.type,
        null,
        {
          logo: selectedFiles.logo,       
          pan: selectedFiles.pan,         
          tan: selectedFiles.tan,
          gst : selectedFiles.gst,
          cancelled_cheque : selectedFiles.cancelledCheque       
       },  
       (response: any) => {
         toast.success(response.message);
         onClose();
         setTimeout(() => {
          window.location.reload();
        }, 3000);
         
      },
     (errMessage: any) => {
         toast.error(errMessage.message);
      });
   }
   else {
    console.log("No values changed");
    onClose();
   }
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
     const fileInput = event.target;
     const file = fileInput.files?.[0] || null;
     if (file) {
       if (file.size > maxFileSize) {
          toast.error("File size must not exceed 1 MB");
          return;
        }
       if (!allowedFileTypes.includes(file.type)) {
          toast.error("File type not supported");
          return;
       }
       setSelectedFiles((prevFiles) => ({
         ...prevFiles,
         [type]: file,
       }));
       setIcons((prevIcons) => ({
          ...prevIcons,
          [type]: { view: true, close: true, upload: false }, 
       }));
       setDocumentList((prevList) =>
        prevList.map((doc) =>
          doc.document_name === type ? { ...doc, location: '', status: 'not uploaded' } : doc
        )
      );
     }
   fileInput.value = '';
  };
  const resetFileSelection = (type: string) => {
    setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [type]: null
    }));
    setIcons((prevIcons) => ({
      ...prevIcons,
      [type]: { view: false, close: false, upload: true }, 
    }));
    setDocumentList((prevList) =>
      prevList.map((doc) =>
        doc.document_name === type ? { ...doc, location: '', status: 'not uploaded' } : doc
      )
    );
    
  };
  const handleIconClick = (type: string, action: string) => {
    if (action === 'view') {
      const document = documentList.find(doc => 
        doc.document_name.toLowerCase() === type.toLowerCase() && doc.status === 'uploaded'
      );
      if (document) {
        const url = document.location;
        window.open(`${global.company_documents_image_url}${url}`, '_blank');
      }  else {
        const file = selectedFiles[type];
        if (file) {
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
        }
      }
    }
    else if (action === 'remove') {
        ConfirmationDeleteModal(type);
    }
  };
  const ConfirmationDeleteModal = ( type: string) => {
    setOpenDeleteModal(true);
    setTypeToDelete(type);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleConfirmDelete = () => {
    setOpenDeleteModal(false);
    resetFileSelection(typeToDelete);
    toast.error("Please upload a new file for the deleted one before save");
  };
  const handleClose = (type: string) => {
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [type]: null,
    }));
    setIcons((prevIcons) => ({
      ...prevIcons,
      [type]: { view: false, close: false, upload: true },
    }));
    setDocumentList((prevList) =>
      prevList.map((doc) =>
        doc.document_name === type ? { ...doc, location: '', status: 'not uploaded' } : doc
      )
    );
  };
  const renderFileInput = (label: string, type: string) => {
    const iconState = icons[type];
    const isSpecialField = label === 'Cancelled Cheque' || label === 'Logo';
    const containerStyle = {
      display: 'flex',
      alignItems: 'center',
      ...(isSpecialField ? {  } : {})
    };
    const viewCloseStyle = {
      cursor: 'pointer',
      color: 'blue',
      marginRight: '10px',
      ...(isSpecialField ? { } : {})
    };
    return (
      <div key={type} style={containerStyle}>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <p style={{ marginRight: '-35px', width: '180px' }}>{label}</p>
          <input
            accept="*"
            style={{ display: 'none' }}
            id={`${type}-file`}
            type="file"
            onChange={(e) => handleFileChange(e, type)}
          />
          <label htmlFor={`${type}-file`}>
            {iconState.upload && (
              <Tooltip title="Upload File">
                <IconButton component="span">
                  <UploadIcon color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </label>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {iconState.view && (
            <Tooltip title="View File">
              <span
                style={viewCloseStyle}
                onClick={() => handleIconClick(type, 'view')}
              >
                View
              </span>
            </Tooltip>
          )}
          {iconState.close && (
            <Tooltip title="Remove File">
              <IconButton sx={{ color: 'red' }} component="span" onClick={() => handleIconClick(type, 'remove')}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <div style={{ margin: '0px 0px 10px 0px' }}>
          <Heading title={`Edit - ${selectedData.company_name}`} closeIcon="true" onClose={onClose} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Tabs
         value={activeTab}
          onChange={(event, newValue) => handleTabChange(newValue)}
         className="tabs"
        >
       <Tab 
         label="Company Details" 
         className={`tab ${activeTab === 0 ? 'tab-selected' : 'tab-default'}`}
        />
        <Tab 
         label="Additional Details" 
         className={`tab ${activeTab === 1 ? 'tab-selected' : 'tab-default'}`}
        />
        <Tab 
        label="Bank Details" 
        className={`tab ${activeTab === 2 ? 'tab-selected' : 'tab-default'}`}
        />
        <Tab 
        label="Documents" 
        className={`tab ${activeTab === 3 ? 'tab-selected' : 'tab-default'}`}
        />
       </Tabs>
          <TabPanel value={activeTab} index={0}>
            <Box className="form-section">
             <Box className="form-group">
              <TextField
                variant="outlined"
                size="small"
                label="Name"
                name="name"
                value={formValues.companyName}
                onChange={handleNameChange('companyName', setCompanyNameError)}
                error={!!companyNameError}
                helperText={companyNameError}
                className="styled-textfield-width"
              />
               <TextField
                variant="outlined"
                size="small"
                name="phone"
                label="Mobile Number"
                value={formValues.mobile_number}
                onChange={handlePhoneNumberChange}
                onBlur={handlePhoneNumberBlur}
                error={!!mobileNumberError}
                helperText={mobileNumberError}
                className="styled-textfield-width"
              />
            </Box>
            <Box className="form-group">
               <Dropdown 
                options={typesOptions}
                selectedValue={formValues.type}
                handleChange={handleTypeChange}
                placeholder="Select Type"
                label="Select Type"
                width="225px"
                height="41px"
              >
              </Dropdown>
              <TextField
                variant="outlined"
                size="small"
                name="email"
                label="Email"
                value={formValues.email}
                onChange={handleEmailChange}
                onBlur={() => handleEmailBlur('email', setEmailError)}
                error={!!emailError}
                helperText={emailError}
                className="styled-textfield-width"
              />
            </Box>
            <Box className="form-group">
              <TextField
                variant="outlined"
                size="small"
                name="address"
                label="Address"
                value={formValues.address}
                onChange={handleAddressChange}
                //onBlur={handleAddressBlur}
                error={!!addressError}
                helperText={addressError}
                className="styled-textfield-width"
              />
              <TextField
                variant="outlined"
                size="small"
                name="pincode"
                label="Pincode"
                value={formValues.pincode}
                onChange={handlePincodeChange}
                onBlur={handlePincodeBlur}
                error={!!pincodeError}
                helperText={pincodeError}
                className="styled-textfield-width"
              />
            </Box>
            <Box className="form-group">
              <TextField
              variant="outlined"
              size="small"
              name="state"
              label="State"
              value = {formValues.state}
              onChange={handleStateChange}
              error={!!stateError}
              helperText={stateError}
              className="styled-textfield-width"
              />
              <TextField
              variant="outlined"
              size="small"
              name="cin"
              label="CIN"
              value = {formValues.cin}
              onChange={ handleCinChange}
              onBlur={handleCinBlur}
              error={!!cinError}
              helperText={cinError}
              className="styled-textfield-width"
              />
              </Box>
             <div style={{ marginLeft: '230px', marginTop: '5px' }}>
             <Button className='custom-button' onClick={() =>handleTabChange(1)}>
              Next
            </Button>
            </div>
           </Box>
         </TabPanel>
         <TabPanel value={activeTab} index={1}>
            <Box className="form-section-additional">
              <Typography variant="h6" style={{ fontSize: '16px',marginBottom: '10px', marginTop: '-10px' }}>
                  Contact Person 1
              </Typography>
            <Box className="form-group-additional">
              <TextField
              variant="outlined"
              size="small"
              label="Name"
              name="contactNameOne"
              value={formValues.contactNameOne}
              onChange={handleNameChange('contactNameOne', setContactNameOneError)}
              error={!!contactNameOneError}
              helperText={contactNameOneError}
              className="styled-textfield-width"
              />
              <TextField
              variant="outlined"
              size="small"
              name="contactNumberOne"
              label="Mobile Number"
              value={formValues.contactNumberOne}
              onChange={ handleContactNumberOneChange}
              onBlur={handleContactNumberOneBlur}
              error={!!contactNumberOneError}
              helperText={contactNumberOneError}
              className="styled-textfield-width"
              />
            </Box>
            <Box className="form-group-additional">
             <TextField
             variant="outlined"
             size="small"
             name="contactEmailOne"
             label="Email"
             value={formValues.contactEmailOne}
             onChange={handleEmailChange}
             onBlur={() => handleEmailBlur('contactEmailOne', setContactEmailOneError)}
             error={!!contactEmailOneError}
             helperText={contactEmailOneError}
             className="styled-textfield-width"
            />
            <TextField
            variant="outlined"
            size="small"
            name="contactDesignationOne"
            label="Designation"
            value={formValues.contactDesignationOne}
            onChange={handleNameChange('contactDesignationOne', setContactDesignationOneError)}
            error={!!contactDesignationOneError}
            helperText={contactDesignationOneError}
            className="styled-textfield-width"
            />
       </Box>
         <Typography variant="h6" style={{ fontSize: '16px',marginBottom: '10px', marginTop: '-10px' }}>
            Contact Person 2
        </Typography>
        <Box className="form-group-additional">
          <TextField
           variant="outlined"
           size="small"
           label="Name"
           name="contactNameTwo"
           value={formValues.contactNameTwo}
           onChange={handleNameChange('contactNameTwo', setContactNameTwoError)}
           error={!!contactNameTwoError}
           helperText={contactNameTwoError}
          className="styled-textfield-width"
          />
          <TextField
          variant="outlined"
          size="small"
          name="contactMobileTwo"
          label="Mobile Number"
          value={formValues.contactNumberTwo}
          onChange={ handleContactNumberTwoChange}
          onBlur={handleContactNumberTwoBlur}
          error={!!contactNumberTwoError}
          helperText={contactNumberTwoError}
          className="styled-textfield-width"
          />
       </Box>
       <Box className="form-group-additional">
          <TextField
          variant="outlined"
          size="small"
          name="contactEmailTwo"
          label="Email"
          value={formValues.contactEmailTwo}
          onChange={handleEmailChange}
          onBlur={() => handleEmailBlur('contactEmailTwo', setContactEmailTwoError)}
          error={!!contactEmailTwoError}
          helperText={contactEmailTwoError}
          className="styled-textfield-width"
         />
        <TextField
        variant="outlined"
        size="small"
        name="contactDesignationTwo"
        label="Designation"
        onChange={handleNameChange('contactDesignationTwo', setContactDesignationTwoError)}
        value={formValues.contactDesignationTwo}
        error={!!contactDesignationTwoError}
        helperText={contactDesignationTwoError}
        className="styled-textfield-width"
        />
      </Box>
      <Typography variant="h6" style={{fontSize: '16px', marginBottom: '10px', marginTop: '-10px' }}>
          Contact Person 3
      </Typography>
      <Box className="form-group-additional">
        <TextField
        variant="outlined"
        size="small"
        label="Name"
        name="contactNameThree"
        value={formValues.contactNameThree}
        onChange={handleNameChange('contactNameThree', setContactNameThreeError)}
        error={!!contactNameThreeError}
        helperText={contactNameThreeError}
        className="styled-textfield-width"
        />
        <TextField
        variant="outlined"
        size="small"
        name="contactMobileThree"
        label="Mobile Number"
        value={formValues.contactNumberThree}
        onChange={ handleContactNumberThreeChange}
        onBlur={handleContactNumberThreeBlur}
        error={!!contactNumberThreeError}
        helperText={contactNumberThreeError}
        className="styled-textfield-width"
        />
      </Box>
      <Box className="form-group-additional">
        <TextField
        variant="outlined"
        size="small"
        name="contactEmailThree"
        label="Email"
        value={formValues.contactEmailThree}
        onChange={handleEmailChange}
        onBlur={() => handleEmailBlur('contactEmailThree', setContactEmailThreeError)}
        error={!!contactEmailThreeError}
        helperText={contactEmailThreeError}
        className="styled-textfield-width"
        />
        <TextField
        variant="outlined"
        size="small"
        name="contactDesignationThree"
        label="Designation"
        value={formValues.contactDesignationThree}
        onChange={handleNameChange('contactDesignationThree', setContactDesignationThreeError)}
        error={!!contactDesignationThreeError}
        helperText={contactDesignationThreeError}
        className="styled-textfield-width"
        />
      </Box>
     <div style={{ marginLeft: '230px', marginTop: '5px' }}>
       <Button className="custom-button" onClick={() =>handleTabChange(2)}>
          Next
       </Button>
     </div>
     </Box>
     </TabPanel>
     <TabPanel value={activeTab} index={2}>
          <Box className="form-section">
            <Box className="form-group">
              <TextField
                variant="outlined"
                size="small"
                label="Bank Name"
                name="bankName"
                value={formValues.bank_details}
                onChange={handleBankNameChange}
                onBlur={handleBankNameBlur}
                error={!!bankNameError}
                helperText={bankNameError}
                className="styled-textfield-width"
              />
              <TextField
                variant="outlined"
                size="small"
                name="branchName"
                label="Branch Name"
                value={formValues.branch_name}
                onChange={handleBranchNameChange}
                error={!!branchNameError}
                helperText={branchNameError}
                className="styled-textfield-width"
              />
            </Box>
            <Box className="form-group">
              <TextField
                variant="outlined"
                size="small"
                name="accountNumber"
                label="Account Number"
                value={formValues.account_number}
                onChange={handleAccountNumberChange}
                onBlur={handleAccountNumberBlur}
                error={!!accountNumberError}
                helperText={accountNumberError}
                className="styled-textfield-width"
              />
               <Dropdown 
                options={accountTypesOptions}
                selectedValue={formValues.account_name}
                handleChange={handleAccountTypeChange}
                placeholder="Select Account Type"
                label={'Select Account Type'}
                width="224px"
                height="41px"
               >
            </Dropdown>
            </Box>
            <Box className="form-group">
              <TextField
                variant="outlined"
                size="small"
                name="ifsc"
                label="IFSC"
                value={formValues.ifsc}
                onChange={handleIfscChange}
                onBlur={handleIfscBlur}
                error={!!ifscError}
                helperText={ifscError}
                className="styled-textfield-width"
              />
            </Box>
            <div style={{ marginLeft: '250px', marginTop: '5px' }}>
            <Button className='custom-button'onClick={() =>handleTabChange(3)}>
            Next
          </Button>
            </div>
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <Box className="form-section-icon">
         <Box className="form-group-icon">
         <Box className="form-input-icon" display="flex" alignItems="center"  gap ='50px'>
          {renderFileInput('PAN', 'pan')} 
           <TextField
              variant="outlined"
              size="small"
              name="pan"
              label="PAN"
              value = {formValues.pan_number}
              onChange={ handlePanChange}
              onBlur={handlePanBlur}
              error={!!panError}
              helperText={panError}
              className="styled-textfield-width"
              />
        </Box>
        <Box className="form-input-icon" display="flex" alignItems="center"  gap ='50px'>
           {renderFileInput('TAN', 'tan')} 
           <TextField
              variant="outlined"
              size="small"
              name="tan"
              label="TAN"
              value = {formValues.tan_number}
              onChange={ handleTanChange}
              onBlur={handleTanBlur}
              error={!!tanError}
              helperText={tanError}
              className="styled-textfield-width"
            />
      </Box>
      <Box className="form-input-icon" display="flex" alignItems="center"  gap ='50px'>
        {renderFileInput('GST', 'gst')} 
        <TextField
              variant="outlined"
              size="small"
              name="gst"
              label="GST"
              value = {formValues.gst_number}
              onChange={ handleGSTChange}
              onBlur={handleGstBlur}
              error={!!gstError}
              helperText={gstError}
              //disabled={!(selectedData.pan_number != formValues.pan_number)}
              className="styled-textfield-width"
              />
        </Box>
        <Box className="form-input-icon">
          {renderFileInput('Cancelled Cheque', 'cancelledCheque')} 
        </Box>
        <Box className="form-input-icon">
          {renderFileInput('Logo', 'logo')} 
        </Box>
        </Box>
      </Box>
      <div style={{ marginLeft: '210px', marginTop: '5px' }}>
        <Button  className='custom-button' onClick={handleClickSave}>
              Save
       </Button>
      </div>
      <div style={{ marginLeft: '280px', marginTop: '-38px' }}>
        <Button  className='custom-cancel-button' onClick={handleClickCancel}>
              cancel
        </Button>
            </div>
      </TabPanel>
        <ConfirmationPopup
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        message={`Are you sure you want to delete this file`}
      />
      </DialogContent>
    </Dialog>
  );
};

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return value === index ? <Box>{children}</Box> : null;
};

export default EditCompanyModal;

import axios, { AxiosResponse, AxiosError } from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const storedUserDetails = localStorage.getItem("userdetails");
const token = storedUserDetails ? JSON.parse(storedUserDetails).token.replace(/^"(.*)"$/, '$1') : null;

interface ErrorResponse {
  status: number;
  message: string;
}

export const getDrivers = (
  driver_leads: string | null,
  blacklist: string | null,
  per_page_records: number,
  page_number: number,
  left: string | null,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: string, statusCode?: number) => void 
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(`${global.api_url}/api/drivers`, {
      params: { driver_leads, blacklist, per_page_records, page_number, left },
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      const errorData = error.response?.data as ErrorResponse | undefined;
      const errorMessage = errorData?.message || "Unknown Error";
      const statusCode = error.response?.status;
      _errorCallBack(errorMessage, statusCode);
    });
};

export const addNonExistingDriver = (
  driver_users_id: number | '', 
  driver_leads_id: number | '',  
  remarks: string | null,          
  status: string | null,           
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`, 
    'Content-Type': 'application/json',
  };
  const payload = {
    driver_users_id, 
    driver_leads_id,
    remarks,
    status,
  };
  axios
    .post(`${global.api_url}/api/drivers_non_existing`, payload, { headers })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 404) {
        _errorCallBack(error.response.data);
      }else if (error.response?.status === 401) {
        localStorage.clear();
      } 
    });
};

export const deleteNonExistingDriver = (
  driver_non_existing_id: number,              
  _successCallBack: (response: any) => void,    
  _errorCallBack: (errMessage: any) => void,     
) => {
  const headers = {
    Authorization: `Bearer ${token}`,           
    'Content-Type': 'application/json',         
  };
  axios
    .delete(`${global.api_url}/api/drivers_non_existing`, {
      params: { driver_non_existing_id },
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);         
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);     
      }else if (error.response?.status === 404) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 401) {
        localStorage.clear();                  
      } else {
        toast.error('Server Error');             
      }
    });
};

export const addLeads = (
  name: string | null, 
  pincode: string | null,  
  phone: string | null,          
  email: string | null,  
  remarks: string | null,         
  _successCallBack: (successMessage: any) => void,  
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`, 
    'Content-Type': 'application/json',
  };
  const payload = {
    name,
    pincode,
    phone,
    email,
    remarks,
  };
  axios
    .post(`${global.api_url}/api/drivers_leads`, payload, { headers })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        console.log('API response:', response.data);
        const successMessage = response.data.message;  
        _successCallBack(successMessage);  
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 404) {
        _errorCallBack(error.response.data);
      }else if (error.response?.status === 401) {
        _errorCallBack(error.response.data); 
      } 
    });
};

export const deleteDriverLead = (
  driver_lead_id: number,               
  _successCallBack: (response: any) => void,    
  _errorCallBack: (errMessage: any) => void     
) => {
  const headers = {
    Authorization: `Bearer ${token}`,    
    'Content-Type': 'application/json',  
  };
  axios
    .delete(`${global.api_url}/api/drivers_leads`, {  
      params: { driver_lead_id },  
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);  
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);  
      }else if (error.response?.status === 404) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 401) {
        localStorage.clear();  
      } else {
        toast.error('Server Error');  
      }
    });
};

export const editDriver = (
  name: string | null,              
  email: string | null,            
  whatsapp_number: string | null,   
  country_code_whatsapp: string | null,  
  language: string | null, 
  user_id:number,         
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`, 
    'Content-Type': 'application/json',
  };
  const payload = {
    name, 
    email, 
    whatsapp_number, 
    country_code_whatsapp, 
    language,
    user_id,
  };
  axios
    .put(`${global.api_url}/api/edit_user`, payload, { headers })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data); 
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data); 
      } 
      else if (error.response?.status === 404) {
        _errorCallBack(error.response.data);
      }else if  (error.response?.status === 401){
        localStorage.clear();
      }
      else{
        toast.error("Server Error");
      }
    });
};

export const editDriverLead = (
  driver_lead_id: string,           
  name: string | null,              
  pincode: string | null,            
  phone: string | null,             
  email: string | null,  
  status: string | null,          
  remarks: string | null,         
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`,  
    'Content-Type': 'application/json',
  };
  const payload = {
    name, 
    pincode, 
    phone, 
    email,  
    status,
    remarks,
  };
  axios
    .put(`${global.api_url}/api/drivers_leads/${driver_lead_id}`, payload, { headers }) 
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data); 
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data); 
      }else if (error.response?.status === 404) {
        _errorCallBack(error.response.data);
      }
       else if (error.response?.status === 401) {
        localStorage.clear(); 
      } 
      else{
        toast.error("Server Error");
      }
    });
};

export const getDocuments = (
  vehicles_id:any,
  users_id: any,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios
    .get(`${global.api_url}/api/documents`, {
      params: {
        vehicles_id: vehicles_id || '',  
        users_id: users_id || ''         
      },
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      }
      else if (error.response?.status === 404) {
        _errorCallBack(error.response.data);
      }
      else if  (error.response?.status === 401){
        localStorage.clear();
      }
      else{
        toast.error("Server Error");
      }
    });
};

export const addDocuments = (
  drivers_license: File | null,
  aadhar: File | null,
  reg_cert: File | null,
  insurance: File | null,
  fitness_cert: File | null,
  pollution_cert: File | null,
  vehicles_id: string|null,
  fitness_expiry: string | null,
  insurance_expiry: string | null,
  users_id : number,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void
) => {
  const formData = new FormData();
  if (drivers_license) formData.append('drivers_license', drivers_license);
  if (aadhar) formData.append('aadhar', aadhar);
  if (reg_cert) formData.append('reg_cert', reg_cert);
  if (insurance) formData.append('insurance', insurance);
  if (fitness_cert) formData.append('fitness_cert', fitness_cert);
  if (pollution_cert) formData.append('pollution_cert', pollution_cert);
  formData.append('vehicles_id', vehicles_id);
  if (fitness_expiry) formData.append('fitness_expiry', fitness_expiry);
  if (insurance_expiry) formData.append('insurance_expiry', insurance_expiry);
  formData.append('users_id', users_id.toString());
  axios.post(`${global.api_url}/api/documents`, formData, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  })
  .then((response: AxiosResponse) => {
    if(response.status === 200){
    _successCallBack(response.data);
  }
    else if(response.status === 201) {
      _successCallBack(response.data);
    }
     })
  .catch((error: AxiosError) => {
    if (error.response?.status === 400) {
      _errorCallBack(error.response.data);
    } 
    else if (error.response?.status === 404) {
      _errorCallBack(error.response.data);
    }else if (error.response?.status === 401) {
      localStorage.clear();
      toast.error("Login Expired.");
      _errorCallBack("Login Expired.");
    } else {
      
      _errorCallBack(error);
    }
  });
};

export const searchDriverLeads = (
  phone: string,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`
  };

  axios
    .get(`${global.api_url}/api/search_drivers_leads`, {
      params: { phone },
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 404) {
        _errorCallBack(error.response.data);
      }
      else if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      } else {
        toast.error("Server Error");
      }
    });
};

export const deleteDocument = (
  userDocumentId:number | null,
  vehicleDocumentId: number | null,
  vehicleId: number | null,
  userId:number | null,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void
) => {
  const headers = {
    Authorization: `Bearer ${token}`
  };
  const data = {
    user_documents_id:userDocumentId,
    veh_documents_id: vehicleDocumentId,
    vehicles_id: vehicleId,
    users_id:userId,
  };
  axios
    .delete(`${global.api_url}/api/documents`, {
      headers: headers,
      data: data 
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } 
      else if (error.response?.status === 404) {
        _errorCallBack(error.response.data);
      }
      else if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      } else {
        toast.error("Server Error");
      }
    });
};

export const getDriverName = (
  phone_number,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios
    .get(`${global.api_url}/api/users/?device_id=&user_phone=${phone_number}&country_code=91`, {
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        console.log("success");
        _successCallBack(response.data);
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      }
      else if (error.response?.status === 404) {
        _errorCallBack(error.response.data);
      }
      else if  (error.response?.status === 401){
        localStorage.clear();
      }
      else{
        toast.error("Server Error");
      }
    });
};



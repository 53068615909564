import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Heading = ({ title, onBackClick, optionalTitle }) => {
  return (
    <div style={{ overflow: 'auto', backgroundColor: '#fff' }}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={onBackClick}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {optionalTitle && (
            <Typography variant="subtitle1" component="div" style={{ marginLeft: 'auto',color:'#68e0cf', fontWeight:'bold' }}>
              {optionalTitle}
            </Typography>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Heading;

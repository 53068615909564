import React, { useState } from 'react';
import './header.scss';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../helper.scss';
import {convertToUpperCase} from '../../Utils/ToUpperCase/to-upper-case.tsx';
const Header = () => {
  const [isOpen, toggleOpen] = useState<boolean>(false);
  const menuClass = `dropdown-menu${isOpen ? " show" : ""}`;
  const storedUserDetails = localStorage.getItem("userdetails");

  const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;
  const userName = userDetails ? userDetails.user.name.replace(/^"(.*)"$/, '$1') : null;
  const userRole = convertToUpperCase(userDetails ? userDetails.role : null);

  return (
    <>
      <nav className="navbar navbar-dropdown fixed-top">
        <form className="form-inline" style={{marginRight:'100px'}}>
          <div className="dropdown" onClick={() => toggleOpen(prevState => !prevState)}>
            <button className="btn btn-primary dropdown-toggle"  type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{ backgroundColor: "#b3fbf0", color: "black", border: "none", fontWeight: "bold" , height:"35px" }}>
              <i className="fas fa-user-circle"></i> &nbsp; {userName}

            </button>
            <ul className={menuClass} aria-labelledby="dropdownMenuButton1">
              <li><a className="dropdown-item" href="#profile"><i className="fas fa-wrench"></i>&nbsp;Profile</a></li>
              <li><a className="dropdown-item" href="#/settings"><i className="fas fa-cog"></i>&nbsp;Settings</a></li>
              <li><a className="dropdown-item" href="/" onClick={() => localStorage.clear()}><i className="fas fa-sign-out-alt"></i>&nbsp;Logout</a></li>
            </ul>
          </div>
        </form>
      </nav>
    </>
  );
}

export default Header;

export const validateConfirmpassword = (confirmPassword: string, password: string): string => {
    const length = confirmPassword.length;
    if (length === 0) return ''; 
    if (length >= 1) {
        if( !/^\d{1}/.test(confirmPassword.substring(0, 1))) {
      return 'Password must be a digit';
        }
      if (password && confirmPassword[0] !== password[0]) {
        return 'Password is not matching';
      }
    }
    if (length >= 2) {
        if( !/^\d{2}/.test(confirmPassword.substring(0, 2))) {
      return 'Password must be a digit';
        }
      if (password && confirmPassword[1] !== password[1]) {
        return 'Password is not matching';
      }
    }
    if (length >= 3) {
        if( !/^\d{3}/.test(confirmPassword.substring(0, 3))) {
      return 'Password must be a digit';
        }
      if (password && confirmPassword[2] !== password[2]) {
        return 'Password is not matching';
      }
    }
    if (length >= 4) {
        if( !/^\d{4}/.test(confirmPassword.substring(0, 4))) {
      return 'Password must be a digit';
        }
      if (password && confirmPassword[3] !== password[3]) {
        return 'Password is not matching';
      }
    }
    if (length > 4) {
        return 'Confirm Password  must be exactly 4 characters';
    }
    return '';
};


  
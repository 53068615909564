import React, { useState, useRef } from 'react';
import {formatVehicleNo} from '../../Utils/VehicleNumberValidation/vehicle-number-validation.tsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  TextField,
  MenuProps,
  FormHelperText,
} from '@mui/material';

interface DropdownProps {
  options: { id: string; label: string }[];
  selectedValue: string;
  handleChange: (event: SelectChangeEvent<string>) => void;
  placeholder?: string;
  width?: string | number;
  height?: string | number;
  label?: string | number;
  searchable?: boolean;
  error?: boolean;
  helperText?: string | null;
  displayEmpty?: boolean;
  name?:string | null;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  selectedValue,
  handleChange,
  placeholder,
  label,
  width,
  height,
  searchable = false,
  error = false,
  helperText = '',
  displayEmpty = false, 
  name, 
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const filteredOptions = options.filter((option) => {
    const label = option.label || '';
    return label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const menuProps: Partial<MenuProps> = {
    PaperProps: {
      style: {
        maxHeight: 300,
        width: width || '100%',
      },
    },
  };

  return (
    <FormControl
      variant="outlined"
      style={{ width: width || '100%', height: height || 'auto' }}
      error={error}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={selectedValue}
        onChange={handleChange}
        label={label}
        style={{ height: height || 'auto' }}
        MenuProps={menuProps}
        displayEmpty={displayEmpty}
      >
        {searchable && (
          <div style={{ padding: 5 }}>
            <TextField
              variant="outlined"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              fullWidth
              onKeyDown={handleKeyDown}
              style={{ marginBottom: 8 }}
              inputRef={inputRef}
            />
          </div>
        )}
        {placeholder && (
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options</MenuItem>
        )}
      </Select>
      {error && helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default Dropdown;

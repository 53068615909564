import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface DocumentsViewModalProps {
  open: boolean;
  onClose: () => void;
  documents: any; 
}

const DocumentsViewModal: React.FC<DocumentsViewModalProps> = ({ open, onClose, documents }) => {
  const [documentList, setDocumentList] = useState<any[]>([]);
  useEffect(() => {
    if (open) {
      const loadedDocuments = [
        { document_name: 'pan', location: documents.pan, status: documents.pan ? 'uploaded' : 'not uploaded' },
        { document_name: 'gst', location: documents.gst, status: documents.gst ? 'uploaded' : 'not uploaded' },
        { document_name: 'tan', location: documents.tan, status: documents.tan ? 'uploaded' : 'not uploaded' },
        { document_name: 'logo', location: documents.logo, status: documents.tan ? 'uploaded' : 'not uploaded' },
        { document_name: 'cancelled_cheque', location: documents.cancelled_cheque, status: documents.cancelled_cheque ? 'uploaded' : 'not uploaded' }
      ];
      setDocumentList(loadedDocuments);
    }
  }, [open, documents]);
  const handleIconClick = (type: string, action: string) => {

    if (action === 'view') {
      const document = documentList.find(doc => 
        doc.document_name.toLowerCase() === type.toLowerCase() && doc.status === 'uploaded'
      );
      if (document) {
        const url = document.location;
        window.open(`${global.company_documents_image_url}${url}`, '_blank');
      } else {
        toast.error('Document not found.');
      }
    }
  };
  const renderFileInput = (label: string, type: string) => {
    const document = documentList.find(doc => doc.document_name === type);
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginRight: '10px', width: '180px' }}>{label}</p>
          <input
            accept="*"
            style={{ display: 'none' }}
            id={`${type}-file`}
            type="file"
          />
          {document && document.status === 'uploaded' && (
            <Tooltip title="View File">
              <span
                style={{ cursor: 'pointer', color: 'blue', marginRight: '10px' }}
                onClick={() => handleIconClick(type, 'view')}
              >
                View
              </span>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: '300px', height: 'auto' } }}>
      <DialogTitle>
        <div style={{ margin: '0px 0px 0px 0px' }}>
          <Heading title='Documents' closeIcon="true" onClose={onClose} />
        </div>
      </DialogTitle>
      <DialogContent>
        {renderFileInput('Pan:', 'pan')}
        {renderFileInput('GST:', 'gst')}
        {renderFileInput('Tan:', 'tan')}
        {renderFileInput('Logo:', 'logo')}
        {renderFileInput('Cancelled Cheque:', 'cancelled_cheque')}
      </DialogContent>
    </Dialog>
  );
};

export default DocumentsViewModal;

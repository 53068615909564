import React from 'react';
import {useState, useEffect} from 'react';
import '../../Components/Button/button-style.scss';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import {SelectChangeEvent , TextField, Box} from '@mui/material';
import {addNonExistingDriver ,deleteNonExistingDriver} from './action.ts';
import {toast} from 'react-toastify';
import ConfirmationPopup from '../../Components/ConfirmationModal/confirmation-modal.tsx';

interface DeleteModalProps {
  open: boolean;
  handleClose: () => void;
  driverId : number;
  remarksToPass : string;
  option : string;
  fetchUsers:(page: number, pageSize: number, lead: string | null, blacklist: string | null, left: string | null, tabName: string) => void;
  page : number;
  pageSize : number;
}
const DeleteModal: React.FC<DeleteModalProps> = ({ open, handleClose, driverId ,remarksToPass ,option,fetchUsers ,page , pageSize}) => {
    const statusOptions = [
        { id: 'blacklist', label: 'Blacklist'},
        { id: 'left', label: 'Left' },
        { id: 'others', label: 'Others' },
    ];
    const number = 1;
    const stringValue = number.toString();
    const [status, setStatus] =  useState('');
    const [remarks, setRemarks] =  useState('');
    useEffect(() => {
        if (remarksToPass) {
          setRemarks(remarksToPass);
        }
    }, [remarksToPass, open]);
    const handleStatusChange = (event: SelectChangeEvent<string>) => {
        setStatus(event.target.value);
    };
    const handleCloseDelete = () => {
      handleClose();
      setStatus('');
      setRemarks('');
    };
    const handleRemarks = (event) => {
        const { value } = event.target;
        setRemarks(value);
    };
    const handleSave = () => {
      if (option==='driver'){
        addNonExistingDriver(
            driverId, 
            null,
            remarks,
            status, 
            (response) => {
                toast.success(response.message);
                handleCloseDelete();
                setTimeout(() => {
                   fetchUsers(page, pageSize, null, null, null, 'driver');
                }, 1000);
            },
            (error) => {
                toast.error(error.message);
                handleCloseDelete();
            }
        );
      }
      else {
          deleteNonExistingDriver(
            driverId,
             (response) => {
                toast.success(response.message);
                handleClose();
                if(option==='blacklist')
                    {  
                      setTimeout(() => {
                         fetchUsers(page, pageSize, null, '1', null, 'blacklist');
                      }, 1000);
                    }
                else
                    {
                      setTimeout(() => {
                         fetchUsers(page, pageSize, null, null, '1', 'left');
                      }, 1000);
                    }
              }, (error) => {
                    toast.error(error.message);
                    handleClose();
               }
          );
        }
    };
    const contentToDisplay = option === 'driver' && (
      <>
        <div style={{ marginLeft: '0px', marginTop: '10px' }}>
          <p style={{ fontSize: '17px' }}>Status</p>
        </div>
        <div style={{ marginLeft: '130px', marginTop: '-26px' }}>
          <Dropdown 
            options={statusOptions}
            selectedValue={status}
            handleChange={handleStatusChange}
            placeholder="Select Status"
            width="224px"
            height="41px"
            displayEmpty={true}
          />
        </div> 
        <div style={{ marginLeft: '0px', marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}>Remarks</p>
        </div>
        <div style={{ marginLeft: '130px', marginTop: '-25px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="remarks"
              value={remarks}
              onChange={handleRemarks}
            />
          </Box>
        </div>
      </>
    );
    return (
     <ConfirmationPopup
      open={open}
      onClose={handleCloseDelete}
      onConfirm={handleSave}
      title = "Delete Driver"
      message="Are you sure you want to delete the driver?"
      contentToDisplay={contentToDisplay}  
     />
    );
};

export default DeleteModal;





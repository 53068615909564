import React, { useEffect, useState, useRef } from 'react';
import { Box, Container, Typography, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Heading from '../../Components/heading.tsx';
import { getDashboard, getTrips } from './action.ts';
import formatDate from "../../../../Utils/DateFormat/date-format.tsx";
import getCurrentDate from '../../../../Utils/CurrentDate/current-date.tsx';
import TripsList from '../../Components/trip_list.tsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'; // Add this line
import axios from 'axios'; // Axios for API calls

const styles = {
  statusContainer: {
    backgroundColor: '#f0f0f0',
    paddingBottom: '16px',
    borderRadius: '8px',
    width: '100%',
    marginTop: "30px"
  },
  tripContainer: {
    backgroundColor: '#f0f0f0',
    paddingRight: '0px',
    paddingLeft: '0px',
  },
  subHeading: {
    fontWeight: 'bold',
    marginTop: '20px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  noTrips:{
    fontWeight: 'bold',
    marginTop: '20px',
    marginLeft: '10px',
    marginRight: '10px',
    alignItems: 'center',
    height:'500px',
    textAlign:'center'
  },
  tripDetailsBox: {
    backgroundColor: 'white',
    padding: '16px',
    marginTop: '10px',
    borderRadius: '8px',
    border: '1px solid #e0e0e0',
    marginLeft: '10px',
    marginRight: '10px',
  },
  detailItem: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid grey',
    padding: '8px 0',
    fontFamily: 'Montserrat, sans-serif',
  },
  refreshBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '8px',
    marginTop: '10px',
    borderRadius: '8px',
    alignItems: 'center',
    marginLeft: '10px',
    marginRight: '10px',
  },
  refreshText: {
    marginLeft: '8px',
  },
  tripsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    fontWeight: 'bold',
  },
  showText: {
    fontWeight: 'bold',
    marginTop: '20px',
    marginLeft: '10px',
    marginRight: '10px',
    color: 'blue',
    cursor: 'pointer',
  },
};

const handleBack = () => {
  window.ReactNativeWebView.postMessage(
    JSON.stringify({ action: 'navigateBackToApp' })
  );
};
const TripsScreen: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  const vehicleId = params.get('vehicle_id');
  const token = params.get('token');
  const currentDate = getCurrentDate();
  const [tripDetails, setTripDetails] = useState<any>({});
  const [noTrips, setNoTrips] = useState(false);
  const [trips, setTrips] = useState([]);
  const [hasMoreTrips, setHasMoreTrips] = useState(true);
  const [page, setPage] = useState(0);
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [currentLocation, setCurrentLocation] = useState<string>('');
  const loading = useRef(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetchTrips();
  }, [page]);
  const fetchTrips = () => {
    if (loading.current || !hasMoreTrips) return;
    loading.current = true;
    getTrips(
      10,
      page,
      currentDate,
      currentDate,
      (response) => {
        const totalTripsCount = response.message.total_trips_count;
        const newTrips = response.message.trips_list;
        if (newTrips.length === 0 || newTrips === 'no trips fetched') {
          setHasMoreTrips(false);
        } else {
          setTrips((prevTrips) => [...prevTrips, ...newTrips]);
        }
        loading.current = false;
        setNoTrips(totalTripsCount === 0);
      },
      (errorMessage) => {
        loading.current = false;
        if (errorMessage.message.total_trips_count === 0) {
          setNoTrips(true);
        }
        if (errorMessage?.message?.errors?.from_date) {
          toast.error(errorMessage.message.errors.from_date);
        } else if (errorMessage?.message?.errors?.to_date) {
          toast.error(errorMessage.message.errors.to_date);
        }
      },
      vehicleId,
      '',
      ''
    );
  };
  useEffect(() => {
    getDashboard(
      (response) => {
        setTripDetails(response.message.current_trip);
        setVehicleNumber(response.message.vehicle.number_plate);
      },
      (errorMessage) => {
        toast.error(errorMessage.data.message);
      }
    );
  }, []);
  const startTime = tripDetails?.start_time;
  const formattedDate = startTime ? startTime.split(' ')[0].split('-').reverse().join('-') : '';
  const startTimeDisplay = startTime ? startTime.split(' ')[1] : '';
  const handleShowMore = () => {
    const newUrl = `/web_view_show_more?number_plate=${vehicleNumber}&vehicle_id=${vehicleId}&token=${token}`;
    console.log('Navigating to URL:', newUrl);
    navigate(newUrl); 
  };
  // const fetchCurrentLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         const { latitude, longitude } = position.coords;
  //         console.log('Latitude:', latitude, 'Longitude:', longitude);
  //         try {
  //           const response = await axios.get(
  //             `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=YOUR_API_KEY`
  //           );
  //           const location = response.data.results[0].formatted;
  //           setCurrentLocation(location);
  //         } catch (error) {
  //           toast.error('Error fetching location');
  //           console.error('Reverse geolocation error:', error);
  //         }
  //       },
  //       (error) => {
  //         if (error.code === error.PERMISSION_DENIED) {
  //           toast.error(
  //             'Geolocation permission denied. Please enable location services in your browser settings.'
  //           );
  //         } else if (error.code === error.POSITION_UNAVAILABLE) {
  //           toast.error('Position unavailable.');
  //         } else if (error.code === error.TIMEOUT) {
  //           toast.error('Request timed out.');
  //         } else {
  //           toast.error('Error getting location: ' + error.message);
  //         }
  //         console.error('Error getting location:', error);
  //       }
  //     );
  //   } else {
  //     toast.error('Geolocation is not supported by this browser.');
  //   }
  // };
  
  return (
    <Container style={styles.tripContainer}>
      <Box>
        <Box style={styles.statusContainer}>
          <Heading title="Trips" onBackClick={handleBack} />
        </Box>
        <Box>
          <Typography style={styles.subHeading}>Current Trips Detail</Typography>
          <Box style={styles.tripDetailsBox}>
            <Box style={styles.detailItem}>
              <Typography>Trip Date</Typography>
              <Typography>{formattedDate}</Typography>
            </Box>
            <Box style={styles.detailItem}>
              <Typography>Start Time</Typography>
              <Typography>{startTimeDisplay}</Typography>
            </Box>
            <Box style={styles.detailItem}>
              <Typography>Elapsed Time</Typography>
              <Typography>{tripDetails?.elapsed_time}</Typography>
            </Box>
            <Box style={styles.detailItem}>
              <Typography>Distance</Typography>
              <Typography></Typography>
            </Box>
          </Box>
          <Typography style={styles.subHeading}>Current Location</Typography>
          <Box style={styles.refreshBox}>
            <Typography>{currentLocation || tripDetails?.start_location }</Typography>
            <IconButton>
              <RefreshIcon />
            </IconButton>
            <Typography style={styles.refreshText}>Refresh</Typography>
          </Box>
          <Box style={styles.tripsContainer}>
            <Typography style={styles.subHeading}>Trips</Typography>
            <Typography style={styles.showText} onClick={handleShowMore}>
              Show more
            </Typography>
          </Box>
          {noTrips ? (
            <Typography style={styles.noTrips}>No trips available</Typography>
          ) : (
            <Box>
              <TripsList trips={trips} />
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default TripsScreen;
